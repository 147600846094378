import { default as regionsRGru1Xo3jRMeta } from "/src/pages/[country-slug]/regions.vue?macro=true";
import { default as indexxnLcM2xszRMeta } from "/src/pages/association/index.vue?macro=true";
import { default as _91vacancy_45slug_93VkyhxbiSuWMeta } from "/src/pages/career/[vacancy-slug].vue?macro=true";
import { default as administrativeoZpxg4BY2DMeta } from "/src/pages/career/administrative.vue?macro=true";
import { default as indexYIZj1mROG5Meta } from "/src/pages/career/index.vue?macro=true";
import { default as youngavJJkNdW25Meta } from "/src/pages/career/young.vue?macro=true";
import { default as collaboration3jvvGd76HaMeta } from "/src/pages/collaboration.vue?macro=true";
import { default as contactsVzkVHECPHfMeta } from "/src/pages/contacts.vue?macro=true";
import { default as cookies2jZ9OMHd38Meta } from "/src/pages/cookies.vue?macro=true";
import { default as disclaimerL4fLtIVQVYMeta } from "/src/pages/disclaimer.vue?macro=true";
import { default as _91slug_93I9TCERRZVgMeta } from "/src/pages/employees/[slug].vue?macro=true";
import { default as indexnU448CBofEMeta } from "/src/pages/employees/index.vue?macro=true";
import { default as _91eventSlug_93gR3ZPCN2T9Meta } from "/src/pages/events/[eventSlug].vue?macro=true";
import { default as indexvsa0KwJWqiMeta } from "/src/pages/index.vue?macro=true";
import { default as _91slug_93k8nKVssDU2Meta } from "/src/pages/industries/[slug].vue?macro=true";
import { default as indexm0BNx4EM8mMeta } from "/src/pages/industries/index.vue?macro=true";
import { default as indexqLpU8qWYj9Meta } from "/src/pages/insights/index.vue?macro=true";
import { default as _91newsSlug_93v9OceKdD1xMeta } from "/src/pages/news/[newsSlug].vue?macro=true";
import { default as _91newsletterSlug_93saYE0yyPLlMeta } from "/src/pages/newsletters/[newsletterSlug].vue?macro=true";
import { default as doing_45businesskhWFlKneRRMeta } from "/src/pages/page/doing-business.vue?macro=true";
import { default as privacy_45policy6lIvnzkpYeMeta } from "/src/pages/privacy-policy.vue?macro=true";
import { default as pro_45bonoAM3ufo0FYHMeta } from "/src/pages/pro-bono.vue?macro=true";
import { default as _91publicationSlug_93zcQOhtCLK9Meta } from "/src/pages/publications/[publicationSlug].vue?macro=true";
import { default as recognitionjC8ahfO9yCMeta } from "/src/pages/recognition.vue?macro=true";
import { default as indexHHxIP9fb8kMeta } from "/src/pages/regions/index.vue?macro=true";
import { default as sitemapiIks4zSRrbMeta } from "/src/pages/sitemap.vue?macro=true";
import { default as _91slug_930dCnukiiaiMeta } from "/src/pages/specializations/[slug].vue?macro=true";
import { default as indexyyzAurYdqzMeta } from "/src/pages/specializations/index.vue?macro=true";
import { default as component_45stubsLJBURSTwrMeta } from "/src/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stubsLJBURSTwr } from "/src/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: regionsRGru1Xo3jRMeta?.name ?? "countryslug-regions___en",
    path: regionsRGru1Xo3jRMeta?.path ?? "/:countryslug()/regions",
    meta: regionsRGru1Xo3jRMeta || {},
    alias: regionsRGru1Xo3jRMeta?.alias || [],
    redirect: regionsRGru1Xo3jRMeta?.redirect,
    component: () => import("/src/pages/[country-slug]/regions.vue").then(m => m.default || m)
  },
  {
    name: regionsRGru1Xo3jRMeta?.name ?? "countryslug-regions___ar",
    path: regionsRGru1Xo3jRMeta?.path ?? "/ar/:countryslug()/regions",
    meta: regionsRGru1Xo3jRMeta || {},
    alias: regionsRGru1Xo3jRMeta?.alias || [],
    redirect: regionsRGru1Xo3jRMeta?.redirect,
    component: () => import("/src/pages/[country-slug]/regions.vue").then(m => m.default || m)
  },
  {
    name: regionsRGru1Xo3jRMeta?.name ?? "countryslug-regions___mn",
    path: regionsRGru1Xo3jRMeta?.path ?? "/mn/:countryslug()/regions",
    meta: regionsRGru1Xo3jRMeta || {},
    alias: regionsRGru1Xo3jRMeta?.alias || [],
    redirect: regionsRGru1Xo3jRMeta?.redirect,
    component: () => import("/src/pages/[country-slug]/regions.vue").then(m => m.default || m)
  },
  {
    name: regionsRGru1Xo3jRMeta?.name ?? "countryslug-regions___ru",
    path: regionsRGru1Xo3jRMeta?.path ?? "/ru/:countryslug()/regions",
    meta: regionsRGru1Xo3jRMeta || {},
    alias: regionsRGru1Xo3jRMeta?.alias || [],
    redirect: regionsRGru1Xo3jRMeta?.redirect,
    component: () => import("/src/pages/[country-slug]/regions.vue").then(m => m.default || m)
  },
  {
    name: regionsRGru1Xo3jRMeta?.name ?? "countryslug-regions___kk",
    path: regionsRGru1Xo3jRMeta?.path ?? "/kk/:countryslug()/regions",
    meta: regionsRGru1Xo3jRMeta || {},
    alias: regionsRGru1Xo3jRMeta?.alias || [],
    redirect: regionsRGru1Xo3jRMeta?.redirect,
    component: () => import("/src/pages/[country-slug]/regions.vue").then(m => m.default || m)
  },
  {
    name: regionsRGru1Xo3jRMeta?.name ?? "countryslug-regions___zh",
    path: regionsRGru1Xo3jRMeta?.path ?? "/zh/:countryslug()/regions",
    meta: regionsRGru1Xo3jRMeta || {},
    alias: regionsRGru1Xo3jRMeta?.alias || [],
    redirect: regionsRGru1Xo3jRMeta?.redirect,
    component: () => import("/src/pages/[country-slug]/regions.vue").then(m => m.default || m)
  },
  {
    name: regionsRGru1Xo3jRMeta?.name ?? "countryslug-regions___tr",
    path: regionsRGru1Xo3jRMeta?.path ?? "/tr/:countryslug()/regions",
    meta: regionsRGru1Xo3jRMeta || {},
    alias: regionsRGru1Xo3jRMeta?.alias || [],
    redirect: regionsRGru1Xo3jRMeta?.redirect,
    component: () => import("/src/pages/[country-slug]/regions.vue").then(m => m.default || m)
  },
  {
    name: regionsRGru1Xo3jRMeta?.name ?? "countryslug-regions___hy",
    path: regionsRGru1Xo3jRMeta?.path ?? "/hy/:countryslug()/regions",
    meta: regionsRGru1Xo3jRMeta || {},
    alias: regionsRGru1Xo3jRMeta?.alias || [],
    redirect: regionsRGru1Xo3jRMeta?.redirect,
    component: () => import("/src/pages/[country-slug]/regions.vue").then(m => m.default || m)
  },
  {
    name: indexxnLcM2xszRMeta?.name ?? "association___en",
    path: indexxnLcM2xszRMeta?.path ?? "/association",
    meta: indexxnLcM2xszRMeta || {},
    alias: indexxnLcM2xszRMeta?.alias || [],
    redirect: indexxnLcM2xszRMeta?.redirect,
    component: () => import("/src/pages/association/index.vue").then(m => m.default || m)
  },
  {
    name: indexxnLcM2xszRMeta?.name ?? "association___ar",
    path: indexxnLcM2xszRMeta?.path ?? "/ar/association",
    meta: indexxnLcM2xszRMeta || {},
    alias: indexxnLcM2xszRMeta?.alias || [],
    redirect: indexxnLcM2xszRMeta?.redirect,
    component: () => import("/src/pages/association/index.vue").then(m => m.default || m)
  },
  {
    name: indexxnLcM2xszRMeta?.name ?? "association___mn",
    path: indexxnLcM2xszRMeta?.path ?? "/mn/association",
    meta: indexxnLcM2xszRMeta || {},
    alias: indexxnLcM2xszRMeta?.alias || [],
    redirect: indexxnLcM2xszRMeta?.redirect,
    component: () => import("/src/pages/association/index.vue").then(m => m.default || m)
  },
  {
    name: indexxnLcM2xszRMeta?.name ?? "association___ru",
    path: indexxnLcM2xszRMeta?.path ?? "/ru/association",
    meta: indexxnLcM2xszRMeta || {},
    alias: indexxnLcM2xszRMeta?.alias || [],
    redirect: indexxnLcM2xszRMeta?.redirect,
    component: () => import("/src/pages/association/index.vue").then(m => m.default || m)
  },
  {
    name: indexxnLcM2xszRMeta?.name ?? "association___kk",
    path: indexxnLcM2xszRMeta?.path ?? "/kk/association",
    meta: indexxnLcM2xszRMeta || {},
    alias: indexxnLcM2xszRMeta?.alias || [],
    redirect: indexxnLcM2xszRMeta?.redirect,
    component: () => import("/src/pages/association/index.vue").then(m => m.default || m)
  },
  {
    name: indexxnLcM2xszRMeta?.name ?? "association___zh",
    path: indexxnLcM2xszRMeta?.path ?? "/zh/association",
    meta: indexxnLcM2xszRMeta || {},
    alias: indexxnLcM2xszRMeta?.alias || [],
    redirect: indexxnLcM2xszRMeta?.redirect,
    component: () => import("/src/pages/association/index.vue").then(m => m.default || m)
  },
  {
    name: indexxnLcM2xszRMeta?.name ?? "association___tr",
    path: indexxnLcM2xszRMeta?.path ?? "/tr/association",
    meta: indexxnLcM2xszRMeta || {},
    alias: indexxnLcM2xszRMeta?.alias || [],
    redirect: indexxnLcM2xszRMeta?.redirect,
    component: () => import("/src/pages/association/index.vue").then(m => m.default || m)
  },
  {
    name: indexxnLcM2xszRMeta?.name ?? "association___hy",
    path: indexxnLcM2xszRMeta?.path ?? "/hy/association",
    meta: indexxnLcM2xszRMeta || {},
    alias: indexxnLcM2xszRMeta?.alias || [],
    redirect: indexxnLcM2xszRMeta?.redirect,
    component: () => import("/src/pages/association/index.vue").then(m => m.default || m)
  },
  {
    name: _91vacancy_45slug_93VkyhxbiSuWMeta?.name ?? "career-vacancyslug___en",
    path: _91vacancy_45slug_93VkyhxbiSuWMeta?.path ?? "/career/:vacancyslug()",
    meta: _91vacancy_45slug_93VkyhxbiSuWMeta || {},
    alias: _91vacancy_45slug_93VkyhxbiSuWMeta?.alias || [],
    redirect: _91vacancy_45slug_93VkyhxbiSuWMeta?.redirect,
    component: () => import("/src/pages/career/[vacancy-slug].vue").then(m => m.default || m)
  },
  {
    name: _91vacancy_45slug_93VkyhxbiSuWMeta?.name ?? "career-vacancyslug___ar",
    path: _91vacancy_45slug_93VkyhxbiSuWMeta?.path ?? "/ar/career/:vacancyslug()",
    meta: _91vacancy_45slug_93VkyhxbiSuWMeta || {},
    alias: _91vacancy_45slug_93VkyhxbiSuWMeta?.alias || [],
    redirect: _91vacancy_45slug_93VkyhxbiSuWMeta?.redirect,
    component: () => import("/src/pages/career/[vacancy-slug].vue").then(m => m.default || m)
  },
  {
    name: _91vacancy_45slug_93VkyhxbiSuWMeta?.name ?? "career-vacancyslug___mn",
    path: _91vacancy_45slug_93VkyhxbiSuWMeta?.path ?? "/mn/career/:vacancyslug()",
    meta: _91vacancy_45slug_93VkyhxbiSuWMeta || {},
    alias: _91vacancy_45slug_93VkyhxbiSuWMeta?.alias || [],
    redirect: _91vacancy_45slug_93VkyhxbiSuWMeta?.redirect,
    component: () => import("/src/pages/career/[vacancy-slug].vue").then(m => m.default || m)
  },
  {
    name: _91vacancy_45slug_93VkyhxbiSuWMeta?.name ?? "career-vacancyslug___ru",
    path: _91vacancy_45slug_93VkyhxbiSuWMeta?.path ?? "/ru/career/:vacancyslug()",
    meta: _91vacancy_45slug_93VkyhxbiSuWMeta || {},
    alias: _91vacancy_45slug_93VkyhxbiSuWMeta?.alias || [],
    redirect: _91vacancy_45slug_93VkyhxbiSuWMeta?.redirect,
    component: () => import("/src/pages/career/[vacancy-slug].vue").then(m => m.default || m)
  },
  {
    name: _91vacancy_45slug_93VkyhxbiSuWMeta?.name ?? "career-vacancyslug___kk",
    path: _91vacancy_45slug_93VkyhxbiSuWMeta?.path ?? "/kk/career/:vacancyslug()",
    meta: _91vacancy_45slug_93VkyhxbiSuWMeta || {},
    alias: _91vacancy_45slug_93VkyhxbiSuWMeta?.alias || [],
    redirect: _91vacancy_45slug_93VkyhxbiSuWMeta?.redirect,
    component: () => import("/src/pages/career/[vacancy-slug].vue").then(m => m.default || m)
  },
  {
    name: _91vacancy_45slug_93VkyhxbiSuWMeta?.name ?? "career-vacancyslug___zh",
    path: _91vacancy_45slug_93VkyhxbiSuWMeta?.path ?? "/zh/career/:vacancyslug()",
    meta: _91vacancy_45slug_93VkyhxbiSuWMeta || {},
    alias: _91vacancy_45slug_93VkyhxbiSuWMeta?.alias || [],
    redirect: _91vacancy_45slug_93VkyhxbiSuWMeta?.redirect,
    component: () => import("/src/pages/career/[vacancy-slug].vue").then(m => m.default || m)
  },
  {
    name: _91vacancy_45slug_93VkyhxbiSuWMeta?.name ?? "career-vacancyslug___tr",
    path: _91vacancy_45slug_93VkyhxbiSuWMeta?.path ?? "/tr/career/:vacancyslug()",
    meta: _91vacancy_45slug_93VkyhxbiSuWMeta || {},
    alias: _91vacancy_45slug_93VkyhxbiSuWMeta?.alias || [],
    redirect: _91vacancy_45slug_93VkyhxbiSuWMeta?.redirect,
    component: () => import("/src/pages/career/[vacancy-slug].vue").then(m => m.default || m)
  },
  {
    name: _91vacancy_45slug_93VkyhxbiSuWMeta?.name ?? "career-vacancyslug___hy",
    path: _91vacancy_45slug_93VkyhxbiSuWMeta?.path ?? "/hy/career/:vacancyslug()",
    meta: _91vacancy_45slug_93VkyhxbiSuWMeta || {},
    alias: _91vacancy_45slug_93VkyhxbiSuWMeta?.alias || [],
    redirect: _91vacancy_45slug_93VkyhxbiSuWMeta?.redirect,
    component: () => import("/src/pages/career/[vacancy-slug].vue").then(m => m.default || m)
  },
  {
    name: administrativeoZpxg4BY2DMeta?.name ?? "career-administrative___en",
    path: administrativeoZpxg4BY2DMeta?.path ?? "/career/administrative",
    meta: administrativeoZpxg4BY2DMeta || {},
    alias: administrativeoZpxg4BY2DMeta?.alias || [],
    redirect: administrativeoZpxg4BY2DMeta?.redirect,
    component: () => import("/src/pages/career/administrative.vue").then(m => m.default || m)
  },
  {
    name: administrativeoZpxg4BY2DMeta?.name ?? "career-administrative___ar",
    path: administrativeoZpxg4BY2DMeta?.path ?? "/ar/career/administrative",
    meta: administrativeoZpxg4BY2DMeta || {},
    alias: administrativeoZpxg4BY2DMeta?.alias || [],
    redirect: administrativeoZpxg4BY2DMeta?.redirect,
    component: () => import("/src/pages/career/administrative.vue").then(m => m.default || m)
  },
  {
    name: administrativeoZpxg4BY2DMeta?.name ?? "career-administrative___mn",
    path: administrativeoZpxg4BY2DMeta?.path ?? "/mn/career/administrative",
    meta: administrativeoZpxg4BY2DMeta || {},
    alias: administrativeoZpxg4BY2DMeta?.alias || [],
    redirect: administrativeoZpxg4BY2DMeta?.redirect,
    component: () => import("/src/pages/career/administrative.vue").then(m => m.default || m)
  },
  {
    name: administrativeoZpxg4BY2DMeta?.name ?? "career-administrative___ru",
    path: administrativeoZpxg4BY2DMeta?.path ?? "/ru/career/administrative",
    meta: administrativeoZpxg4BY2DMeta || {},
    alias: administrativeoZpxg4BY2DMeta?.alias || [],
    redirect: administrativeoZpxg4BY2DMeta?.redirect,
    component: () => import("/src/pages/career/administrative.vue").then(m => m.default || m)
  },
  {
    name: administrativeoZpxg4BY2DMeta?.name ?? "career-administrative___kk",
    path: administrativeoZpxg4BY2DMeta?.path ?? "/kk/career/administrative",
    meta: administrativeoZpxg4BY2DMeta || {},
    alias: administrativeoZpxg4BY2DMeta?.alias || [],
    redirect: administrativeoZpxg4BY2DMeta?.redirect,
    component: () => import("/src/pages/career/administrative.vue").then(m => m.default || m)
  },
  {
    name: administrativeoZpxg4BY2DMeta?.name ?? "career-administrative___zh",
    path: administrativeoZpxg4BY2DMeta?.path ?? "/zh/career/administrative",
    meta: administrativeoZpxg4BY2DMeta || {},
    alias: administrativeoZpxg4BY2DMeta?.alias || [],
    redirect: administrativeoZpxg4BY2DMeta?.redirect,
    component: () => import("/src/pages/career/administrative.vue").then(m => m.default || m)
  },
  {
    name: administrativeoZpxg4BY2DMeta?.name ?? "career-administrative___tr",
    path: administrativeoZpxg4BY2DMeta?.path ?? "/tr/career/administrative",
    meta: administrativeoZpxg4BY2DMeta || {},
    alias: administrativeoZpxg4BY2DMeta?.alias || [],
    redirect: administrativeoZpxg4BY2DMeta?.redirect,
    component: () => import("/src/pages/career/administrative.vue").then(m => m.default || m)
  },
  {
    name: administrativeoZpxg4BY2DMeta?.name ?? "career-administrative___hy",
    path: administrativeoZpxg4BY2DMeta?.path ?? "/hy/career/administrative",
    meta: administrativeoZpxg4BY2DMeta || {},
    alias: administrativeoZpxg4BY2DMeta?.alias || [],
    redirect: administrativeoZpxg4BY2DMeta?.redirect,
    component: () => import("/src/pages/career/administrative.vue").then(m => m.default || m)
  },
  {
    name: indexYIZj1mROG5Meta?.name ?? "career___en",
    path: indexYIZj1mROG5Meta?.path ?? "/career",
    meta: indexYIZj1mROG5Meta || {},
    alias: indexYIZj1mROG5Meta?.alias || [],
    redirect: indexYIZj1mROG5Meta?.redirect,
    component: () => import("/src/pages/career/index.vue").then(m => m.default || m)
  },
  {
    name: indexYIZj1mROG5Meta?.name ?? "career___ar",
    path: indexYIZj1mROG5Meta?.path ?? "/ar/career",
    meta: indexYIZj1mROG5Meta || {},
    alias: indexYIZj1mROG5Meta?.alias || [],
    redirect: indexYIZj1mROG5Meta?.redirect,
    component: () => import("/src/pages/career/index.vue").then(m => m.default || m)
  },
  {
    name: indexYIZj1mROG5Meta?.name ?? "career___mn",
    path: indexYIZj1mROG5Meta?.path ?? "/mn/career",
    meta: indexYIZj1mROG5Meta || {},
    alias: indexYIZj1mROG5Meta?.alias || [],
    redirect: indexYIZj1mROG5Meta?.redirect,
    component: () => import("/src/pages/career/index.vue").then(m => m.default || m)
  },
  {
    name: indexYIZj1mROG5Meta?.name ?? "career___ru",
    path: indexYIZj1mROG5Meta?.path ?? "/ru/career",
    meta: indexYIZj1mROG5Meta || {},
    alias: indexYIZj1mROG5Meta?.alias || [],
    redirect: indexYIZj1mROG5Meta?.redirect,
    component: () => import("/src/pages/career/index.vue").then(m => m.default || m)
  },
  {
    name: indexYIZj1mROG5Meta?.name ?? "career___kk",
    path: indexYIZj1mROG5Meta?.path ?? "/kk/career",
    meta: indexYIZj1mROG5Meta || {},
    alias: indexYIZj1mROG5Meta?.alias || [],
    redirect: indexYIZj1mROG5Meta?.redirect,
    component: () => import("/src/pages/career/index.vue").then(m => m.default || m)
  },
  {
    name: indexYIZj1mROG5Meta?.name ?? "career___zh",
    path: indexYIZj1mROG5Meta?.path ?? "/zh/career",
    meta: indexYIZj1mROG5Meta || {},
    alias: indexYIZj1mROG5Meta?.alias || [],
    redirect: indexYIZj1mROG5Meta?.redirect,
    component: () => import("/src/pages/career/index.vue").then(m => m.default || m)
  },
  {
    name: indexYIZj1mROG5Meta?.name ?? "career___tr",
    path: indexYIZj1mROG5Meta?.path ?? "/tr/career",
    meta: indexYIZj1mROG5Meta || {},
    alias: indexYIZj1mROG5Meta?.alias || [],
    redirect: indexYIZj1mROG5Meta?.redirect,
    component: () => import("/src/pages/career/index.vue").then(m => m.default || m)
  },
  {
    name: indexYIZj1mROG5Meta?.name ?? "career___hy",
    path: indexYIZj1mROG5Meta?.path ?? "/hy/career",
    meta: indexYIZj1mROG5Meta || {},
    alias: indexYIZj1mROG5Meta?.alias || [],
    redirect: indexYIZj1mROG5Meta?.redirect,
    component: () => import("/src/pages/career/index.vue").then(m => m.default || m)
  },
  {
    name: youngavJJkNdW25Meta?.name ?? "career-young___en",
    path: youngavJJkNdW25Meta?.path ?? "/career/young",
    meta: youngavJJkNdW25Meta || {},
    alias: youngavJJkNdW25Meta?.alias || [],
    redirect: youngavJJkNdW25Meta?.redirect,
    component: () => import("/src/pages/career/young.vue").then(m => m.default || m)
  },
  {
    name: youngavJJkNdW25Meta?.name ?? "career-young___ar",
    path: youngavJJkNdW25Meta?.path ?? "/ar/career/young",
    meta: youngavJJkNdW25Meta || {},
    alias: youngavJJkNdW25Meta?.alias || [],
    redirect: youngavJJkNdW25Meta?.redirect,
    component: () => import("/src/pages/career/young.vue").then(m => m.default || m)
  },
  {
    name: youngavJJkNdW25Meta?.name ?? "career-young___mn",
    path: youngavJJkNdW25Meta?.path ?? "/mn/career/young",
    meta: youngavJJkNdW25Meta || {},
    alias: youngavJJkNdW25Meta?.alias || [],
    redirect: youngavJJkNdW25Meta?.redirect,
    component: () => import("/src/pages/career/young.vue").then(m => m.default || m)
  },
  {
    name: youngavJJkNdW25Meta?.name ?? "career-young___ru",
    path: youngavJJkNdW25Meta?.path ?? "/ru/career/young",
    meta: youngavJJkNdW25Meta || {},
    alias: youngavJJkNdW25Meta?.alias || [],
    redirect: youngavJJkNdW25Meta?.redirect,
    component: () => import("/src/pages/career/young.vue").then(m => m.default || m)
  },
  {
    name: youngavJJkNdW25Meta?.name ?? "career-young___kk",
    path: youngavJJkNdW25Meta?.path ?? "/kk/career/young",
    meta: youngavJJkNdW25Meta || {},
    alias: youngavJJkNdW25Meta?.alias || [],
    redirect: youngavJJkNdW25Meta?.redirect,
    component: () => import("/src/pages/career/young.vue").then(m => m.default || m)
  },
  {
    name: youngavJJkNdW25Meta?.name ?? "career-young___zh",
    path: youngavJJkNdW25Meta?.path ?? "/zh/career/young",
    meta: youngavJJkNdW25Meta || {},
    alias: youngavJJkNdW25Meta?.alias || [],
    redirect: youngavJJkNdW25Meta?.redirect,
    component: () => import("/src/pages/career/young.vue").then(m => m.default || m)
  },
  {
    name: youngavJJkNdW25Meta?.name ?? "career-young___tr",
    path: youngavJJkNdW25Meta?.path ?? "/tr/career/young",
    meta: youngavJJkNdW25Meta || {},
    alias: youngavJJkNdW25Meta?.alias || [],
    redirect: youngavJJkNdW25Meta?.redirect,
    component: () => import("/src/pages/career/young.vue").then(m => m.default || m)
  },
  {
    name: youngavJJkNdW25Meta?.name ?? "career-young___hy",
    path: youngavJJkNdW25Meta?.path ?? "/hy/career/young",
    meta: youngavJJkNdW25Meta || {},
    alias: youngavJJkNdW25Meta?.alias || [],
    redirect: youngavJJkNdW25Meta?.redirect,
    component: () => import("/src/pages/career/young.vue").then(m => m.default || m)
  },
  {
    name: collaboration3jvvGd76HaMeta?.name ?? "collaboration___en",
    path: collaboration3jvvGd76HaMeta?.path ?? "/collaboration",
    meta: collaboration3jvvGd76HaMeta || {},
    alias: collaboration3jvvGd76HaMeta?.alias || [],
    redirect: collaboration3jvvGd76HaMeta?.redirect,
    component: () => import("/src/pages/collaboration.vue").then(m => m.default || m)
  },
  {
    name: collaboration3jvvGd76HaMeta?.name ?? "collaboration___ar",
    path: collaboration3jvvGd76HaMeta?.path ?? "/ar/collaboration",
    meta: collaboration3jvvGd76HaMeta || {},
    alias: collaboration3jvvGd76HaMeta?.alias || [],
    redirect: collaboration3jvvGd76HaMeta?.redirect,
    component: () => import("/src/pages/collaboration.vue").then(m => m.default || m)
  },
  {
    name: collaboration3jvvGd76HaMeta?.name ?? "collaboration___mn",
    path: collaboration3jvvGd76HaMeta?.path ?? "/mn/collaboration",
    meta: collaboration3jvvGd76HaMeta || {},
    alias: collaboration3jvvGd76HaMeta?.alias || [],
    redirect: collaboration3jvvGd76HaMeta?.redirect,
    component: () => import("/src/pages/collaboration.vue").then(m => m.default || m)
  },
  {
    name: collaboration3jvvGd76HaMeta?.name ?? "collaboration___ru",
    path: collaboration3jvvGd76HaMeta?.path ?? "/ru/collaboration",
    meta: collaboration3jvvGd76HaMeta || {},
    alias: collaboration3jvvGd76HaMeta?.alias || [],
    redirect: collaboration3jvvGd76HaMeta?.redirect,
    component: () => import("/src/pages/collaboration.vue").then(m => m.default || m)
  },
  {
    name: collaboration3jvvGd76HaMeta?.name ?? "collaboration___kk",
    path: collaboration3jvvGd76HaMeta?.path ?? "/kk/collaboration",
    meta: collaboration3jvvGd76HaMeta || {},
    alias: collaboration3jvvGd76HaMeta?.alias || [],
    redirect: collaboration3jvvGd76HaMeta?.redirect,
    component: () => import("/src/pages/collaboration.vue").then(m => m.default || m)
  },
  {
    name: collaboration3jvvGd76HaMeta?.name ?? "collaboration___zh",
    path: collaboration3jvvGd76HaMeta?.path ?? "/zh/collaboration",
    meta: collaboration3jvvGd76HaMeta || {},
    alias: collaboration3jvvGd76HaMeta?.alias || [],
    redirect: collaboration3jvvGd76HaMeta?.redirect,
    component: () => import("/src/pages/collaboration.vue").then(m => m.default || m)
  },
  {
    name: collaboration3jvvGd76HaMeta?.name ?? "collaboration___tr",
    path: collaboration3jvvGd76HaMeta?.path ?? "/tr/collaboration",
    meta: collaboration3jvvGd76HaMeta || {},
    alias: collaboration3jvvGd76HaMeta?.alias || [],
    redirect: collaboration3jvvGd76HaMeta?.redirect,
    component: () => import("/src/pages/collaboration.vue").then(m => m.default || m)
  },
  {
    name: collaboration3jvvGd76HaMeta?.name ?? "collaboration___hy",
    path: collaboration3jvvGd76HaMeta?.path ?? "/hy/collaboration",
    meta: collaboration3jvvGd76HaMeta || {},
    alias: collaboration3jvvGd76HaMeta?.alias || [],
    redirect: collaboration3jvvGd76HaMeta?.redirect,
    component: () => import("/src/pages/collaboration.vue").then(m => m.default || m)
  },
  {
    name: contactsVzkVHECPHfMeta?.name ?? "contacts___en",
    path: contactsVzkVHECPHfMeta?.path ?? "/contacts",
    meta: contactsVzkVHECPHfMeta || {},
    alias: contactsVzkVHECPHfMeta?.alias || [],
    redirect: contactsVzkVHECPHfMeta?.redirect,
    component: () => import("/src/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: contactsVzkVHECPHfMeta?.name ?? "contacts___ar",
    path: contactsVzkVHECPHfMeta?.path ?? "/ar/contacts",
    meta: contactsVzkVHECPHfMeta || {},
    alias: contactsVzkVHECPHfMeta?.alias || [],
    redirect: contactsVzkVHECPHfMeta?.redirect,
    component: () => import("/src/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: contactsVzkVHECPHfMeta?.name ?? "contacts___mn",
    path: contactsVzkVHECPHfMeta?.path ?? "/mn/contacts",
    meta: contactsVzkVHECPHfMeta || {},
    alias: contactsVzkVHECPHfMeta?.alias || [],
    redirect: contactsVzkVHECPHfMeta?.redirect,
    component: () => import("/src/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: contactsVzkVHECPHfMeta?.name ?? "contacts___ru",
    path: contactsVzkVHECPHfMeta?.path ?? "/ru/contacts",
    meta: contactsVzkVHECPHfMeta || {},
    alias: contactsVzkVHECPHfMeta?.alias || [],
    redirect: contactsVzkVHECPHfMeta?.redirect,
    component: () => import("/src/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: contactsVzkVHECPHfMeta?.name ?? "contacts___kk",
    path: contactsVzkVHECPHfMeta?.path ?? "/kk/contacts",
    meta: contactsVzkVHECPHfMeta || {},
    alias: contactsVzkVHECPHfMeta?.alias || [],
    redirect: contactsVzkVHECPHfMeta?.redirect,
    component: () => import("/src/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: contactsVzkVHECPHfMeta?.name ?? "contacts___zh",
    path: contactsVzkVHECPHfMeta?.path ?? "/zh/contacts",
    meta: contactsVzkVHECPHfMeta || {},
    alias: contactsVzkVHECPHfMeta?.alias || [],
    redirect: contactsVzkVHECPHfMeta?.redirect,
    component: () => import("/src/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: contactsVzkVHECPHfMeta?.name ?? "contacts___tr",
    path: contactsVzkVHECPHfMeta?.path ?? "/tr/contacts",
    meta: contactsVzkVHECPHfMeta || {},
    alias: contactsVzkVHECPHfMeta?.alias || [],
    redirect: contactsVzkVHECPHfMeta?.redirect,
    component: () => import("/src/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: contactsVzkVHECPHfMeta?.name ?? "contacts___hy",
    path: contactsVzkVHECPHfMeta?.path ?? "/hy/contacts",
    meta: contactsVzkVHECPHfMeta || {},
    alias: contactsVzkVHECPHfMeta?.alias || [],
    redirect: contactsVzkVHECPHfMeta?.redirect,
    component: () => import("/src/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: cookies2jZ9OMHd38Meta?.name ?? "cookies___en",
    path: cookies2jZ9OMHd38Meta?.path ?? "/cookies",
    meta: cookies2jZ9OMHd38Meta || {},
    alias: cookies2jZ9OMHd38Meta?.alias || [],
    redirect: cookies2jZ9OMHd38Meta?.redirect,
    component: () => import("/src/pages/cookies.vue").then(m => m.default || m)
  },
  {
    name: cookies2jZ9OMHd38Meta?.name ?? "cookies___ar",
    path: cookies2jZ9OMHd38Meta?.path ?? "/ar/cookies",
    meta: cookies2jZ9OMHd38Meta || {},
    alias: cookies2jZ9OMHd38Meta?.alias || [],
    redirect: cookies2jZ9OMHd38Meta?.redirect,
    component: () => import("/src/pages/cookies.vue").then(m => m.default || m)
  },
  {
    name: cookies2jZ9OMHd38Meta?.name ?? "cookies___mn",
    path: cookies2jZ9OMHd38Meta?.path ?? "/mn/cookies",
    meta: cookies2jZ9OMHd38Meta || {},
    alias: cookies2jZ9OMHd38Meta?.alias || [],
    redirect: cookies2jZ9OMHd38Meta?.redirect,
    component: () => import("/src/pages/cookies.vue").then(m => m.default || m)
  },
  {
    name: cookies2jZ9OMHd38Meta?.name ?? "cookies___ru",
    path: cookies2jZ9OMHd38Meta?.path ?? "/ru/cookies",
    meta: cookies2jZ9OMHd38Meta || {},
    alias: cookies2jZ9OMHd38Meta?.alias || [],
    redirect: cookies2jZ9OMHd38Meta?.redirect,
    component: () => import("/src/pages/cookies.vue").then(m => m.default || m)
  },
  {
    name: cookies2jZ9OMHd38Meta?.name ?? "cookies___kk",
    path: cookies2jZ9OMHd38Meta?.path ?? "/kk/cookies",
    meta: cookies2jZ9OMHd38Meta || {},
    alias: cookies2jZ9OMHd38Meta?.alias || [],
    redirect: cookies2jZ9OMHd38Meta?.redirect,
    component: () => import("/src/pages/cookies.vue").then(m => m.default || m)
  },
  {
    name: cookies2jZ9OMHd38Meta?.name ?? "cookies___zh",
    path: cookies2jZ9OMHd38Meta?.path ?? "/zh/cookies",
    meta: cookies2jZ9OMHd38Meta || {},
    alias: cookies2jZ9OMHd38Meta?.alias || [],
    redirect: cookies2jZ9OMHd38Meta?.redirect,
    component: () => import("/src/pages/cookies.vue").then(m => m.default || m)
  },
  {
    name: cookies2jZ9OMHd38Meta?.name ?? "cookies___tr",
    path: cookies2jZ9OMHd38Meta?.path ?? "/tr/cookies",
    meta: cookies2jZ9OMHd38Meta || {},
    alias: cookies2jZ9OMHd38Meta?.alias || [],
    redirect: cookies2jZ9OMHd38Meta?.redirect,
    component: () => import("/src/pages/cookies.vue").then(m => m.default || m)
  },
  {
    name: cookies2jZ9OMHd38Meta?.name ?? "cookies___hy",
    path: cookies2jZ9OMHd38Meta?.path ?? "/hy/cookies",
    meta: cookies2jZ9OMHd38Meta || {},
    alias: cookies2jZ9OMHd38Meta?.alias || [],
    redirect: cookies2jZ9OMHd38Meta?.redirect,
    component: () => import("/src/pages/cookies.vue").then(m => m.default || m)
  },
  {
    name: disclaimerL4fLtIVQVYMeta?.name ?? "disclaimer___en",
    path: disclaimerL4fLtIVQVYMeta?.path ?? "/disclaimer",
    meta: disclaimerL4fLtIVQVYMeta || {},
    alias: disclaimerL4fLtIVQVYMeta?.alias || [],
    redirect: disclaimerL4fLtIVQVYMeta?.redirect,
    component: () => import("/src/pages/disclaimer.vue").then(m => m.default || m)
  },
  {
    name: disclaimerL4fLtIVQVYMeta?.name ?? "disclaimer___ar",
    path: disclaimerL4fLtIVQVYMeta?.path ?? "/ar/disclaimer",
    meta: disclaimerL4fLtIVQVYMeta || {},
    alias: disclaimerL4fLtIVQVYMeta?.alias || [],
    redirect: disclaimerL4fLtIVQVYMeta?.redirect,
    component: () => import("/src/pages/disclaimer.vue").then(m => m.default || m)
  },
  {
    name: disclaimerL4fLtIVQVYMeta?.name ?? "disclaimer___mn",
    path: disclaimerL4fLtIVQVYMeta?.path ?? "/mn/disclaimer",
    meta: disclaimerL4fLtIVQVYMeta || {},
    alias: disclaimerL4fLtIVQVYMeta?.alias || [],
    redirect: disclaimerL4fLtIVQVYMeta?.redirect,
    component: () => import("/src/pages/disclaimer.vue").then(m => m.default || m)
  },
  {
    name: disclaimerL4fLtIVQVYMeta?.name ?? "disclaimer___ru",
    path: disclaimerL4fLtIVQVYMeta?.path ?? "/ru/disclaimer",
    meta: disclaimerL4fLtIVQVYMeta || {},
    alias: disclaimerL4fLtIVQVYMeta?.alias || [],
    redirect: disclaimerL4fLtIVQVYMeta?.redirect,
    component: () => import("/src/pages/disclaimer.vue").then(m => m.default || m)
  },
  {
    name: disclaimerL4fLtIVQVYMeta?.name ?? "disclaimer___kk",
    path: disclaimerL4fLtIVQVYMeta?.path ?? "/kk/disclaimer",
    meta: disclaimerL4fLtIVQVYMeta || {},
    alias: disclaimerL4fLtIVQVYMeta?.alias || [],
    redirect: disclaimerL4fLtIVQVYMeta?.redirect,
    component: () => import("/src/pages/disclaimer.vue").then(m => m.default || m)
  },
  {
    name: disclaimerL4fLtIVQVYMeta?.name ?? "disclaimer___zh",
    path: disclaimerL4fLtIVQVYMeta?.path ?? "/zh/disclaimer",
    meta: disclaimerL4fLtIVQVYMeta || {},
    alias: disclaimerL4fLtIVQVYMeta?.alias || [],
    redirect: disclaimerL4fLtIVQVYMeta?.redirect,
    component: () => import("/src/pages/disclaimer.vue").then(m => m.default || m)
  },
  {
    name: disclaimerL4fLtIVQVYMeta?.name ?? "disclaimer___tr",
    path: disclaimerL4fLtIVQVYMeta?.path ?? "/tr/disclaimer",
    meta: disclaimerL4fLtIVQVYMeta || {},
    alias: disclaimerL4fLtIVQVYMeta?.alias || [],
    redirect: disclaimerL4fLtIVQVYMeta?.redirect,
    component: () => import("/src/pages/disclaimer.vue").then(m => m.default || m)
  },
  {
    name: disclaimerL4fLtIVQVYMeta?.name ?? "disclaimer___hy",
    path: disclaimerL4fLtIVQVYMeta?.path ?? "/hy/disclaimer",
    meta: disclaimerL4fLtIVQVYMeta || {},
    alias: disclaimerL4fLtIVQVYMeta?.alias || [],
    redirect: disclaimerL4fLtIVQVYMeta?.redirect,
    component: () => import("/src/pages/disclaimer.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93I9TCERRZVgMeta?.name ?? "employees-slug___en",
    path: _91slug_93I9TCERRZVgMeta?.path ?? "/employees/:slug()",
    meta: _91slug_93I9TCERRZVgMeta || {},
    alias: _91slug_93I9TCERRZVgMeta?.alias || [],
    redirect: _91slug_93I9TCERRZVgMeta?.redirect,
    component: () => import("/src/pages/employees/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93I9TCERRZVgMeta?.name ?? "employees-slug___ar",
    path: _91slug_93I9TCERRZVgMeta?.path ?? "/ar/employees/:slug()",
    meta: _91slug_93I9TCERRZVgMeta || {},
    alias: _91slug_93I9TCERRZVgMeta?.alias || [],
    redirect: _91slug_93I9TCERRZVgMeta?.redirect,
    component: () => import("/src/pages/employees/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93I9TCERRZVgMeta?.name ?? "employees-slug___mn",
    path: _91slug_93I9TCERRZVgMeta?.path ?? "/mn/employees/:slug()",
    meta: _91slug_93I9TCERRZVgMeta || {},
    alias: _91slug_93I9TCERRZVgMeta?.alias || [],
    redirect: _91slug_93I9TCERRZVgMeta?.redirect,
    component: () => import("/src/pages/employees/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93I9TCERRZVgMeta?.name ?? "employees-slug___ru",
    path: _91slug_93I9TCERRZVgMeta?.path ?? "/ru/employees/:slug()",
    meta: _91slug_93I9TCERRZVgMeta || {},
    alias: _91slug_93I9TCERRZVgMeta?.alias || [],
    redirect: _91slug_93I9TCERRZVgMeta?.redirect,
    component: () => import("/src/pages/employees/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93I9TCERRZVgMeta?.name ?? "employees-slug___kk",
    path: _91slug_93I9TCERRZVgMeta?.path ?? "/kk/employees/:slug()",
    meta: _91slug_93I9TCERRZVgMeta || {},
    alias: _91slug_93I9TCERRZVgMeta?.alias || [],
    redirect: _91slug_93I9TCERRZVgMeta?.redirect,
    component: () => import("/src/pages/employees/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93I9TCERRZVgMeta?.name ?? "employees-slug___zh",
    path: _91slug_93I9TCERRZVgMeta?.path ?? "/zh/employees/:slug()",
    meta: _91slug_93I9TCERRZVgMeta || {},
    alias: _91slug_93I9TCERRZVgMeta?.alias || [],
    redirect: _91slug_93I9TCERRZVgMeta?.redirect,
    component: () => import("/src/pages/employees/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93I9TCERRZVgMeta?.name ?? "employees-slug___tr",
    path: _91slug_93I9TCERRZVgMeta?.path ?? "/tr/employees/:slug()",
    meta: _91slug_93I9TCERRZVgMeta || {},
    alias: _91slug_93I9TCERRZVgMeta?.alias || [],
    redirect: _91slug_93I9TCERRZVgMeta?.redirect,
    component: () => import("/src/pages/employees/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93I9TCERRZVgMeta?.name ?? "employees-slug___hy",
    path: _91slug_93I9TCERRZVgMeta?.path ?? "/hy/employees/:slug()",
    meta: _91slug_93I9TCERRZVgMeta || {},
    alias: _91slug_93I9TCERRZVgMeta?.alias || [],
    redirect: _91slug_93I9TCERRZVgMeta?.redirect,
    component: () => import("/src/pages/employees/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexnU448CBofEMeta?.name ?? "employees___en",
    path: indexnU448CBofEMeta?.path ?? "/employees",
    meta: indexnU448CBofEMeta || {},
    alias: indexnU448CBofEMeta?.alias || [],
    redirect: indexnU448CBofEMeta?.redirect,
    component: () => import("/src/pages/employees/index.vue").then(m => m.default || m)
  },
  {
    name: indexnU448CBofEMeta?.name ?? "employees___ar",
    path: indexnU448CBofEMeta?.path ?? "/ar/employees",
    meta: indexnU448CBofEMeta || {},
    alias: indexnU448CBofEMeta?.alias || [],
    redirect: indexnU448CBofEMeta?.redirect,
    component: () => import("/src/pages/employees/index.vue").then(m => m.default || m)
  },
  {
    name: indexnU448CBofEMeta?.name ?? "employees___mn",
    path: indexnU448CBofEMeta?.path ?? "/mn/employees",
    meta: indexnU448CBofEMeta || {},
    alias: indexnU448CBofEMeta?.alias || [],
    redirect: indexnU448CBofEMeta?.redirect,
    component: () => import("/src/pages/employees/index.vue").then(m => m.default || m)
  },
  {
    name: indexnU448CBofEMeta?.name ?? "employees___ru",
    path: indexnU448CBofEMeta?.path ?? "/ru/employees",
    meta: indexnU448CBofEMeta || {},
    alias: indexnU448CBofEMeta?.alias || [],
    redirect: indexnU448CBofEMeta?.redirect,
    component: () => import("/src/pages/employees/index.vue").then(m => m.default || m)
  },
  {
    name: indexnU448CBofEMeta?.name ?? "employees___kk",
    path: indexnU448CBofEMeta?.path ?? "/kk/employees",
    meta: indexnU448CBofEMeta || {},
    alias: indexnU448CBofEMeta?.alias || [],
    redirect: indexnU448CBofEMeta?.redirect,
    component: () => import("/src/pages/employees/index.vue").then(m => m.default || m)
  },
  {
    name: indexnU448CBofEMeta?.name ?? "employees___zh",
    path: indexnU448CBofEMeta?.path ?? "/zh/employees",
    meta: indexnU448CBofEMeta || {},
    alias: indexnU448CBofEMeta?.alias || [],
    redirect: indexnU448CBofEMeta?.redirect,
    component: () => import("/src/pages/employees/index.vue").then(m => m.default || m)
  },
  {
    name: indexnU448CBofEMeta?.name ?? "employees___tr",
    path: indexnU448CBofEMeta?.path ?? "/tr/employees",
    meta: indexnU448CBofEMeta || {},
    alias: indexnU448CBofEMeta?.alias || [],
    redirect: indexnU448CBofEMeta?.redirect,
    component: () => import("/src/pages/employees/index.vue").then(m => m.default || m)
  },
  {
    name: indexnU448CBofEMeta?.name ?? "employees___hy",
    path: indexnU448CBofEMeta?.path ?? "/hy/employees",
    meta: indexnU448CBofEMeta || {},
    alias: indexnU448CBofEMeta?.alias || [],
    redirect: indexnU448CBofEMeta?.redirect,
    component: () => import("/src/pages/employees/index.vue").then(m => m.default || m)
  },
  {
    name: _91eventSlug_93gR3ZPCN2T9Meta?.name ?? "events-eventSlug___en",
    path: _91eventSlug_93gR3ZPCN2T9Meta?.path ?? "/events/:eventSlug()",
    meta: _91eventSlug_93gR3ZPCN2T9Meta || {},
    alias: _91eventSlug_93gR3ZPCN2T9Meta?.alias || [],
    redirect: _91eventSlug_93gR3ZPCN2T9Meta?.redirect,
    component: () => import("/src/pages/events/[eventSlug].vue").then(m => m.default || m)
  },
  {
    name: _91eventSlug_93gR3ZPCN2T9Meta?.name ?? "events-eventSlug___ar",
    path: _91eventSlug_93gR3ZPCN2T9Meta?.path ?? "/ar/events/:eventSlug()",
    meta: _91eventSlug_93gR3ZPCN2T9Meta || {},
    alias: _91eventSlug_93gR3ZPCN2T9Meta?.alias || [],
    redirect: _91eventSlug_93gR3ZPCN2T9Meta?.redirect,
    component: () => import("/src/pages/events/[eventSlug].vue").then(m => m.default || m)
  },
  {
    name: _91eventSlug_93gR3ZPCN2T9Meta?.name ?? "events-eventSlug___mn",
    path: _91eventSlug_93gR3ZPCN2T9Meta?.path ?? "/mn/events/:eventSlug()",
    meta: _91eventSlug_93gR3ZPCN2T9Meta || {},
    alias: _91eventSlug_93gR3ZPCN2T9Meta?.alias || [],
    redirect: _91eventSlug_93gR3ZPCN2T9Meta?.redirect,
    component: () => import("/src/pages/events/[eventSlug].vue").then(m => m.default || m)
  },
  {
    name: _91eventSlug_93gR3ZPCN2T9Meta?.name ?? "events-eventSlug___ru",
    path: _91eventSlug_93gR3ZPCN2T9Meta?.path ?? "/ru/events/:eventSlug()",
    meta: _91eventSlug_93gR3ZPCN2T9Meta || {},
    alias: _91eventSlug_93gR3ZPCN2T9Meta?.alias || [],
    redirect: _91eventSlug_93gR3ZPCN2T9Meta?.redirect,
    component: () => import("/src/pages/events/[eventSlug].vue").then(m => m.default || m)
  },
  {
    name: _91eventSlug_93gR3ZPCN2T9Meta?.name ?? "events-eventSlug___kk",
    path: _91eventSlug_93gR3ZPCN2T9Meta?.path ?? "/kk/events/:eventSlug()",
    meta: _91eventSlug_93gR3ZPCN2T9Meta || {},
    alias: _91eventSlug_93gR3ZPCN2T9Meta?.alias || [],
    redirect: _91eventSlug_93gR3ZPCN2T9Meta?.redirect,
    component: () => import("/src/pages/events/[eventSlug].vue").then(m => m.default || m)
  },
  {
    name: _91eventSlug_93gR3ZPCN2T9Meta?.name ?? "events-eventSlug___zh",
    path: _91eventSlug_93gR3ZPCN2T9Meta?.path ?? "/zh/events/:eventSlug()",
    meta: _91eventSlug_93gR3ZPCN2T9Meta || {},
    alias: _91eventSlug_93gR3ZPCN2T9Meta?.alias || [],
    redirect: _91eventSlug_93gR3ZPCN2T9Meta?.redirect,
    component: () => import("/src/pages/events/[eventSlug].vue").then(m => m.default || m)
  },
  {
    name: _91eventSlug_93gR3ZPCN2T9Meta?.name ?? "events-eventSlug___tr",
    path: _91eventSlug_93gR3ZPCN2T9Meta?.path ?? "/tr/events/:eventSlug()",
    meta: _91eventSlug_93gR3ZPCN2T9Meta || {},
    alias: _91eventSlug_93gR3ZPCN2T9Meta?.alias || [],
    redirect: _91eventSlug_93gR3ZPCN2T9Meta?.redirect,
    component: () => import("/src/pages/events/[eventSlug].vue").then(m => m.default || m)
  },
  {
    name: _91eventSlug_93gR3ZPCN2T9Meta?.name ?? "events-eventSlug___hy",
    path: _91eventSlug_93gR3ZPCN2T9Meta?.path ?? "/hy/events/:eventSlug()",
    meta: _91eventSlug_93gR3ZPCN2T9Meta || {},
    alias: _91eventSlug_93gR3ZPCN2T9Meta?.alias || [],
    redirect: _91eventSlug_93gR3ZPCN2T9Meta?.redirect,
    component: () => import("/src/pages/events/[eventSlug].vue").then(m => m.default || m)
  },
  {
    name: indexvsa0KwJWqiMeta?.name ?? "index___en",
    path: indexvsa0KwJWqiMeta?.path ?? "/",
    meta: indexvsa0KwJWqiMeta || {},
    alias: indexvsa0KwJWqiMeta?.alias || [],
    redirect: indexvsa0KwJWqiMeta?.redirect,
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexvsa0KwJWqiMeta?.name ?? "index___ar",
    path: indexvsa0KwJWqiMeta?.path ?? "/ar",
    meta: indexvsa0KwJWqiMeta || {},
    alias: indexvsa0KwJWqiMeta?.alias || [],
    redirect: indexvsa0KwJWqiMeta?.redirect,
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexvsa0KwJWqiMeta?.name ?? "index___mn",
    path: indexvsa0KwJWqiMeta?.path ?? "/mn",
    meta: indexvsa0KwJWqiMeta || {},
    alias: indexvsa0KwJWqiMeta?.alias || [],
    redirect: indexvsa0KwJWqiMeta?.redirect,
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexvsa0KwJWqiMeta?.name ?? "index___ru",
    path: indexvsa0KwJWqiMeta?.path ?? "/ru",
    meta: indexvsa0KwJWqiMeta || {},
    alias: indexvsa0KwJWqiMeta?.alias || [],
    redirect: indexvsa0KwJWqiMeta?.redirect,
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexvsa0KwJWqiMeta?.name ?? "index___kk",
    path: indexvsa0KwJWqiMeta?.path ?? "/kk",
    meta: indexvsa0KwJWqiMeta || {},
    alias: indexvsa0KwJWqiMeta?.alias || [],
    redirect: indexvsa0KwJWqiMeta?.redirect,
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexvsa0KwJWqiMeta?.name ?? "index___zh",
    path: indexvsa0KwJWqiMeta?.path ?? "/zh",
    meta: indexvsa0KwJWqiMeta || {},
    alias: indexvsa0KwJWqiMeta?.alias || [],
    redirect: indexvsa0KwJWqiMeta?.redirect,
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexvsa0KwJWqiMeta?.name ?? "index___tr",
    path: indexvsa0KwJWqiMeta?.path ?? "/tr",
    meta: indexvsa0KwJWqiMeta || {},
    alias: indexvsa0KwJWqiMeta?.alias || [],
    redirect: indexvsa0KwJWqiMeta?.redirect,
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexvsa0KwJWqiMeta?.name ?? "index___hy",
    path: indexvsa0KwJWqiMeta?.path ?? "/hy",
    meta: indexvsa0KwJWqiMeta || {},
    alias: indexvsa0KwJWqiMeta?.alias || [],
    redirect: indexvsa0KwJWqiMeta?.redirect,
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k8nKVssDU2Meta?.name ?? "industries-slug___en",
    path: _91slug_93k8nKVssDU2Meta?.path ?? "/industries/:slug()",
    meta: _91slug_93k8nKVssDU2Meta || {},
    alias: _91slug_93k8nKVssDU2Meta?.alias || [],
    redirect: _91slug_93k8nKVssDU2Meta?.redirect,
    component: () => import("/src/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k8nKVssDU2Meta?.name ?? "industries-slug___ar",
    path: _91slug_93k8nKVssDU2Meta?.path ?? "/ar/industries/:slug()",
    meta: _91slug_93k8nKVssDU2Meta || {},
    alias: _91slug_93k8nKVssDU2Meta?.alias || [],
    redirect: _91slug_93k8nKVssDU2Meta?.redirect,
    component: () => import("/src/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k8nKVssDU2Meta?.name ?? "industries-slug___mn",
    path: _91slug_93k8nKVssDU2Meta?.path ?? "/mn/industries/:slug()",
    meta: _91slug_93k8nKVssDU2Meta || {},
    alias: _91slug_93k8nKVssDU2Meta?.alias || [],
    redirect: _91slug_93k8nKVssDU2Meta?.redirect,
    component: () => import("/src/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k8nKVssDU2Meta?.name ?? "industries-slug___ru",
    path: _91slug_93k8nKVssDU2Meta?.path ?? "/ru/industries/:slug()",
    meta: _91slug_93k8nKVssDU2Meta || {},
    alias: _91slug_93k8nKVssDU2Meta?.alias || [],
    redirect: _91slug_93k8nKVssDU2Meta?.redirect,
    component: () => import("/src/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k8nKVssDU2Meta?.name ?? "industries-slug___kk",
    path: _91slug_93k8nKVssDU2Meta?.path ?? "/kk/industries/:slug()",
    meta: _91slug_93k8nKVssDU2Meta || {},
    alias: _91slug_93k8nKVssDU2Meta?.alias || [],
    redirect: _91slug_93k8nKVssDU2Meta?.redirect,
    component: () => import("/src/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k8nKVssDU2Meta?.name ?? "industries-slug___zh",
    path: _91slug_93k8nKVssDU2Meta?.path ?? "/zh/industries/:slug()",
    meta: _91slug_93k8nKVssDU2Meta || {},
    alias: _91slug_93k8nKVssDU2Meta?.alias || [],
    redirect: _91slug_93k8nKVssDU2Meta?.redirect,
    component: () => import("/src/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k8nKVssDU2Meta?.name ?? "industries-slug___tr",
    path: _91slug_93k8nKVssDU2Meta?.path ?? "/tr/industries/:slug()",
    meta: _91slug_93k8nKVssDU2Meta || {},
    alias: _91slug_93k8nKVssDU2Meta?.alias || [],
    redirect: _91slug_93k8nKVssDU2Meta?.redirect,
    component: () => import("/src/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k8nKVssDU2Meta?.name ?? "industries-slug___hy",
    path: _91slug_93k8nKVssDU2Meta?.path ?? "/hy/industries/:slug()",
    meta: _91slug_93k8nKVssDU2Meta || {},
    alias: _91slug_93k8nKVssDU2Meta?.alias || [],
    redirect: _91slug_93k8nKVssDU2Meta?.redirect,
    component: () => import("/src/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexm0BNx4EM8mMeta?.name ?? "industries___en",
    path: indexm0BNx4EM8mMeta?.path ?? "/industries",
    meta: indexm0BNx4EM8mMeta || {},
    alias: indexm0BNx4EM8mMeta?.alias || [],
    redirect: indexm0BNx4EM8mMeta?.redirect,
    component: () => import("/src/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexm0BNx4EM8mMeta?.name ?? "industries___ar",
    path: indexm0BNx4EM8mMeta?.path ?? "/ar/industries",
    meta: indexm0BNx4EM8mMeta || {},
    alias: indexm0BNx4EM8mMeta?.alias || [],
    redirect: indexm0BNx4EM8mMeta?.redirect,
    component: () => import("/src/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexm0BNx4EM8mMeta?.name ?? "industries___mn",
    path: indexm0BNx4EM8mMeta?.path ?? "/mn/industries",
    meta: indexm0BNx4EM8mMeta || {},
    alias: indexm0BNx4EM8mMeta?.alias || [],
    redirect: indexm0BNx4EM8mMeta?.redirect,
    component: () => import("/src/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexm0BNx4EM8mMeta?.name ?? "industries___ru",
    path: indexm0BNx4EM8mMeta?.path ?? "/ru/industries",
    meta: indexm0BNx4EM8mMeta || {},
    alias: indexm0BNx4EM8mMeta?.alias || [],
    redirect: indexm0BNx4EM8mMeta?.redirect,
    component: () => import("/src/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexm0BNx4EM8mMeta?.name ?? "industries___kk",
    path: indexm0BNx4EM8mMeta?.path ?? "/kk/industries",
    meta: indexm0BNx4EM8mMeta || {},
    alias: indexm0BNx4EM8mMeta?.alias || [],
    redirect: indexm0BNx4EM8mMeta?.redirect,
    component: () => import("/src/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexm0BNx4EM8mMeta?.name ?? "industries___zh",
    path: indexm0BNx4EM8mMeta?.path ?? "/zh/industries",
    meta: indexm0BNx4EM8mMeta || {},
    alias: indexm0BNx4EM8mMeta?.alias || [],
    redirect: indexm0BNx4EM8mMeta?.redirect,
    component: () => import("/src/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexm0BNx4EM8mMeta?.name ?? "industries___tr",
    path: indexm0BNx4EM8mMeta?.path ?? "/tr/industries",
    meta: indexm0BNx4EM8mMeta || {},
    alias: indexm0BNx4EM8mMeta?.alias || [],
    redirect: indexm0BNx4EM8mMeta?.redirect,
    component: () => import("/src/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexm0BNx4EM8mMeta?.name ?? "industries___hy",
    path: indexm0BNx4EM8mMeta?.path ?? "/hy/industries",
    meta: indexm0BNx4EM8mMeta || {},
    alias: indexm0BNx4EM8mMeta?.alias || [],
    redirect: indexm0BNx4EM8mMeta?.redirect,
    component: () => import("/src/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexqLpU8qWYj9Meta?.name ?? "insights___en",
    path: indexqLpU8qWYj9Meta?.path ?? "/insights",
    meta: indexqLpU8qWYj9Meta || {},
    alias: indexqLpU8qWYj9Meta?.alias || [],
    redirect: indexqLpU8qWYj9Meta?.redirect,
    component: () => import("/src/pages/insights/index.vue").then(m => m.default || m)
  },
  {
    name: indexqLpU8qWYj9Meta?.name ?? "insights___ar",
    path: indexqLpU8qWYj9Meta?.path ?? "/ar/insights",
    meta: indexqLpU8qWYj9Meta || {},
    alias: indexqLpU8qWYj9Meta?.alias || [],
    redirect: indexqLpU8qWYj9Meta?.redirect,
    component: () => import("/src/pages/insights/index.vue").then(m => m.default || m)
  },
  {
    name: indexqLpU8qWYj9Meta?.name ?? "insights___mn",
    path: indexqLpU8qWYj9Meta?.path ?? "/mn/insights",
    meta: indexqLpU8qWYj9Meta || {},
    alias: indexqLpU8qWYj9Meta?.alias || [],
    redirect: indexqLpU8qWYj9Meta?.redirect,
    component: () => import("/src/pages/insights/index.vue").then(m => m.default || m)
  },
  {
    name: indexqLpU8qWYj9Meta?.name ?? "insights___ru",
    path: indexqLpU8qWYj9Meta?.path ?? "/ru/insights",
    meta: indexqLpU8qWYj9Meta || {},
    alias: indexqLpU8qWYj9Meta?.alias || [],
    redirect: indexqLpU8qWYj9Meta?.redirect,
    component: () => import("/src/pages/insights/index.vue").then(m => m.default || m)
  },
  {
    name: indexqLpU8qWYj9Meta?.name ?? "insights___kk",
    path: indexqLpU8qWYj9Meta?.path ?? "/kk/insights",
    meta: indexqLpU8qWYj9Meta || {},
    alias: indexqLpU8qWYj9Meta?.alias || [],
    redirect: indexqLpU8qWYj9Meta?.redirect,
    component: () => import("/src/pages/insights/index.vue").then(m => m.default || m)
  },
  {
    name: indexqLpU8qWYj9Meta?.name ?? "insights___zh",
    path: indexqLpU8qWYj9Meta?.path ?? "/zh/insights",
    meta: indexqLpU8qWYj9Meta || {},
    alias: indexqLpU8qWYj9Meta?.alias || [],
    redirect: indexqLpU8qWYj9Meta?.redirect,
    component: () => import("/src/pages/insights/index.vue").then(m => m.default || m)
  },
  {
    name: indexqLpU8qWYj9Meta?.name ?? "insights___tr",
    path: indexqLpU8qWYj9Meta?.path ?? "/tr/insights",
    meta: indexqLpU8qWYj9Meta || {},
    alias: indexqLpU8qWYj9Meta?.alias || [],
    redirect: indexqLpU8qWYj9Meta?.redirect,
    component: () => import("/src/pages/insights/index.vue").then(m => m.default || m)
  },
  {
    name: indexqLpU8qWYj9Meta?.name ?? "insights___hy",
    path: indexqLpU8qWYj9Meta?.path ?? "/hy/insights",
    meta: indexqLpU8qWYj9Meta || {},
    alias: indexqLpU8qWYj9Meta?.alias || [],
    redirect: indexqLpU8qWYj9Meta?.redirect,
    component: () => import("/src/pages/insights/index.vue").then(m => m.default || m)
  },
  {
    name: _91newsSlug_93v9OceKdD1xMeta?.name ?? "news-newsSlug___en",
    path: _91newsSlug_93v9OceKdD1xMeta?.path ?? "/news/:newsSlug()",
    meta: _91newsSlug_93v9OceKdD1xMeta || {},
    alias: _91newsSlug_93v9OceKdD1xMeta?.alias || [],
    redirect: _91newsSlug_93v9OceKdD1xMeta?.redirect,
    component: () => import("/src/pages/news/[newsSlug].vue").then(m => m.default || m)
  },
  {
    name: _91newsSlug_93v9OceKdD1xMeta?.name ?? "news-newsSlug___ar",
    path: _91newsSlug_93v9OceKdD1xMeta?.path ?? "/ar/news/:newsSlug()",
    meta: _91newsSlug_93v9OceKdD1xMeta || {},
    alias: _91newsSlug_93v9OceKdD1xMeta?.alias || [],
    redirect: _91newsSlug_93v9OceKdD1xMeta?.redirect,
    component: () => import("/src/pages/news/[newsSlug].vue").then(m => m.default || m)
  },
  {
    name: _91newsSlug_93v9OceKdD1xMeta?.name ?? "news-newsSlug___mn",
    path: _91newsSlug_93v9OceKdD1xMeta?.path ?? "/mn/news/:newsSlug()",
    meta: _91newsSlug_93v9OceKdD1xMeta || {},
    alias: _91newsSlug_93v9OceKdD1xMeta?.alias || [],
    redirect: _91newsSlug_93v9OceKdD1xMeta?.redirect,
    component: () => import("/src/pages/news/[newsSlug].vue").then(m => m.default || m)
  },
  {
    name: _91newsSlug_93v9OceKdD1xMeta?.name ?? "news-newsSlug___ru",
    path: _91newsSlug_93v9OceKdD1xMeta?.path ?? "/ru/news/:newsSlug()",
    meta: _91newsSlug_93v9OceKdD1xMeta || {},
    alias: _91newsSlug_93v9OceKdD1xMeta?.alias || [],
    redirect: _91newsSlug_93v9OceKdD1xMeta?.redirect,
    component: () => import("/src/pages/news/[newsSlug].vue").then(m => m.default || m)
  },
  {
    name: _91newsSlug_93v9OceKdD1xMeta?.name ?? "news-newsSlug___kk",
    path: _91newsSlug_93v9OceKdD1xMeta?.path ?? "/kk/news/:newsSlug()",
    meta: _91newsSlug_93v9OceKdD1xMeta || {},
    alias: _91newsSlug_93v9OceKdD1xMeta?.alias || [],
    redirect: _91newsSlug_93v9OceKdD1xMeta?.redirect,
    component: () => import("/src/pages/news/[newsSlug].vue").then(m => m.default || m)
  },
  {
    name: _91newsSlug_93v9OceKdD1xMeta?.name ?? "news-newsSlug___zh",
    path: _91newsSlug_93v9OceKdD1xMeta?.path ?? "/zh/news/:newsSlug()",
    meta: _91newsSlug_93v9OceKdD1xMeta || {},
    alias: _91newsSlug_93v9OceKdD1xMeta?.alias || [],
    redirect: _91newsSlug_93v9OceKdD1xMeta?.redirect,
    component: () => import("/src/pages/news/[newsSlug].vue").then(m => m.default || m)
  },
  {
    name: _91newsSlug_93v9OceKdD1xMeta?.name ?? "news-newsSlug___tr",
    path: _91newsSlug_93v9OceKdD1xMeta?.path ?? "/tr/news/:newsSlug()",
    meta: _91newsSlug_93v9OceKdD1xMeta || {},
    alias: _91newsSlug_93v9OceKdD1xMeta?.alias || [],
    redirect: _91newsSlug_93v9OceKdD1xMeta?.redirect,
    component: () => import("/src/pages/news/[newsSlug].vue").then(m => m.default || m)
  },
  {
    name: _91newsSlug_93v9OceKdD1xMeta?.name ?? "news-newsSlug___hy",
    path: _91newsSlug_93v9OceKdD1xMeta?.path ?? "/hy/news/:newsSlug()",
    meta: _91newsSlug_93v9OceKdD1xMeta || {},
    alias: _91newsSlug_93v9OceKdD1xMeta?.alias || [],
    redirect: _91newsSlug_93v9OceKdD1xMeta?.redirect,
    component: () => import("/src/pages/news/[newsSlug].vue").then(m => m.default || m)
  },
  {
    name: _91newsletterSlug_93saYE0yyPLlMeta?.name ?? "newsletters-newsletterSlug___en",
    path: _91newsletterSlug_93saYE0yyPLlMeta?.path ?? "/newsletters/:newsletterSlug()",
    meta: _91newsletterSlug_93saYE0yyPLlMeta || {},
    alias: _91newsletterSlug_93saYE0yyPLlMeta?.alias || [],
    redirect: _91newsletterSlug_93saYE0yyPLlMeta?.redirect,
    component: () => import("/src/pages/newsletters/[newsletterSlug].vue").then(m => m.default || m)
  },
  {
    name: _91newsletterSlug_93saYE0yyPLlMeta?.name ?? "newsletters-newsletterSlug___ar",
    path: _91newsletterSlug_93saYE0yyPLlMeta?.path ?? "/ar/newsletters/:newsletterSlug()",
    meta: _91newsletterSlug_93saYE0yyPLlMeta || {},
    alias: _91newsletterSlug_93saYE0yyPLlMeta?.alias || [],
    redirect: _91newsletterSlug_93saYE0yyPLlMeta?.redirect,
    component: () => import("/src/pages/newsletters/[newsletterSlug].vue").then(m => m.default || m)
  },
  {
    name: _91newsletterSlug_93saYE0yyPLlMeta?.name ?? "newsletters-newsletterSlug___mn",
    path: _91newsletterSlug_93saYE0yyPLlMeta?.path ?? "/mn/newsletters/:newsletterSlug()",
    meta: _91newsletterSlug_93saYE0yyPLlMeta || {},
    alias: _91newsletterSlug_93saYE0yyPLlMeta?.alias || [],
    redirect: _91newsletterSlug_93saYE0yyPLlMeta?.redirect,
    component: () => import("/src/pages/newsletters/[newsletterSlug].vue").then(m => m.default || m)
  },
  {
    name: _91newsletterSlug_93saYE0yyPLlMeta?.name ?? "newsletters-newsletterSlug___ru",
    path: _91newsletterSlug_93saYE0yyPLlMeta?.path ?? "/ru/newsletters/:newsletterSlug()",
    meta: _91newsletterSlug_93saYE0yyPLlMeta || {},
    alias: _91newsletterSlug_93saYE0yyPLlMeta?.alias || [],
    redirect: _91newsletterSlug_93saYE0yyPLlMeta?.redirect,
    component: () => import("/src/pages/newsletters/[newsletterSlug].vue").then(m => m.default || m)
  },
  {
    name: _91newsletterSlug_93saYE0yyPLlMeta?.name ?? "newsletters-newsletterSlug___kk",
    path: _91newsletterSlug_93saYE0yyPLlMeta?.path ?? "/kk/newsletters/:newsletterSlug()",
    meta: _91newsletterSlug_93saYE0yyPLlMeta || {},
    alias: _91newsletterSlug_93saYE0yyPLlMeta?.alias || [],
    redirect: _91newsletterSlug_93saYE0yyPLlMeta?.redirect,
    component: () => import("/src/pages/newsletters/[newsletterSlug].vue").then(m => m.default || m)
  },
  {
    name: _91newsletterSlug_93saYE0yyPLlMeta?.name ?? "newsletters-newsletterSlug___zh",
    path: _91newsletterSlug_93saYE0yyPLlMeta?.path ?? "/zh/newsletters/:newsletterSlug()",
    meta: _91newsletterSlug_93saYE0yyPLlMeta || {},
    alias: _91newsletterSlug_93saYE0yyPLlMeta?.alias || [],
    redirect: _91newsletterSlug_93saYE0yyPLlMeta?.redirect,
    component: () => import("/src/pages/newsletters/[newsletterSlug].vue").then(m => m.default || m)
  },
  {
    name: _91newsletterSlug_93saYE0yyPLlMeta?.name ?? "newsletters-newsletterSlug___tr",
    path: _91newsletterSlug_93saYE0yyPLlMeta?.path ?? "/tr/newsletters/:newsletterSlug()",
    meta: _91newsletterSlug_93saYE0yyPLlMeta || {},
    alias: _91newsletterSlug_93saYE0yyPLlMeta?.alias || [],
    redirect: _91newsletterSlug_93saYE0yyPLlMeta?.redirect,
    component: () => import("/src/pages/newsletters/[newsletterSlug].vue").then(m => m.default || m)
  },
  {
    name: _91newsletterSlug_93saYE0yyPLlMeta?.name ?? "newsletters-newsletterSlug___hy",
    path: _91newsletterSlug_93saYE0yyPLlMeta?.path ?? "/hy/newsletters/:newsletterSlug()",
    meta: _91newsletterSlug_93saYE0yyPLlMeta || {},
    alias: _91newsletterSlug_93saYE0yyPLlMeta?.alias || [],
    redirect: _91newsletterSlug_93saYE0yyPLlMeta?.redirect,
    component: () => import("/src/pages/newsletters/[newsletterSlug].vue").then(m => m.default || m)
  },
  {
    name: doing_45businesskhWFlKneRRMeta?.name ?? "page-doing-business___en",
    path: doing_45businesskhWFlKneRRMeta?.path ?? "/page/doing-business",
    meta: doing_45businesskhWFlKneRRMeta || {},
    alias: doing_45businesskhWFlKneRRMeta?.alias || [],
    redirect: doing_45businesskhWFlKneRRMeta?.redirect,
    component: () => import("/src/pages/page/doing-business.vue").then(m => m.default || m)
  },
  {
    name: doing_45businesskhWFlKneRRMeta?.name ?? "page-doing-business___ar",
    path: doing_45businesskhWFlKneRRMeta?.path ?? "/ar/page/doing-business",
    meta: doing_45businesskhWFlKneRRMeta || {},
    alias: doing_45businesskhWFlKneRRMeta?.alias || [],
    redirect: doing_45businesskhWFlKneRRMeta?.redirect,
    component: () => import("/src/pages/page/doing-business.vue").then(m => m.default || m)
  },
  {
    name: doing_45businesskhWFlKneRRMeta?.name ?? "page-doing-business___mn",
    path: doing_45businesskhWFlKneRRMeta?.path ?? "/mn/page/doing-business",
    meta: doing_45businesskhWFlKneRRMeta || {},
    alias: doing_45businesskhWFlKneRRMeta?.alias || [],
    redirect: doing_45businesskhWFlKneRRMeta?.redirect,
    component: () => import("/src/pages/page/doing-business.vue").then(m => m.default || m)
  },
  {
    name: doing_45businesskhWFlKneRRMeta?.name ?? "page-doing-business___ru",
    path: doing_45businesskhWFlKneRRMeta?.path ?? "/ru/page/doing-business",
    meta: doing_45businesskhWFlKneRRMeta || {},
    alias: doing_45businesskhWFlKneRRMeta?.alias || [],
    redirect: doing_45businesskhWFlKneRRMeta?.redirect,
    component: () => import("/src/pages/page/doing-business.vue").then(m => m.default || m)
  },
  {
    name: doing_45businesskhWFlKneRRMeta?.name ?? "page-doing-business___kk",
    path: doing_45businesskhWFlKneRRMeta?.path ?? "/kk/page/doing-business",
    meta: doing_45businesskhWFlKneRRMeta || {},
    alias: doing_45businesskhWFlKneRRMeta?.alias || [],
    redirect: doing_45businesskhWFlKneRRMeta?.redirect,
    component: () => import("/src/pages/page/doing-business.vue").then(m => m.default || m)
  },
  {
    name: doing_45businesskhWFlKneRRMeta?.name ?? "page-doing-business___zh",
    path: doing_45businesskhWFlKneRRMeta?.path ?? "/zh/page/doing-business",
    meta: doing_45businesskhWFlKneRRMeta || {},
    alias: doing_45businesskhWFlKneRRMeta?.alias || [],
    redirect: doing_45businesskhWFlKneRRMeta?.redirect,
    component: () => import("/src/pages/page/doing-business.vue").then(m => m.default || m)
  },
  {
    name: doing_45businesskhWFlKneRRMeta?.name ?? "page-doing-business___tr",
    path: doing_45businesskhWFlKneRRMeta?.path ?? "/tr/page/doing-business",
    meta: doing_45businesskhWFlKneRRMeta || {},
    alias: doing_45businesskhWFlKneRRMeta?.alias || [],
    redirect: doing_45businesskhWFlKneRRMeta?.redirect,
    component: () => import("/src/pages/page/doing-business.vue").then(m => m.default || m)
  },
  {
    name: doing_45businesskhWFlKneRRMeta?.name ?? "page-doing-business___hy",
    path: doing_45businesskhWFlKneRRMeta?.path ?? "/hy/page/doing-business",
    meta: doing_45businesskhWFlKneRRMeta || {},
    alias: doing_45businesskhWFlKneRRMeta?.alias || [],
    redirect: doing_45businesskhWFlKneRRMeta?.redirect,
    component: () => import("/src/pages/page/doing-business.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policy6lIvnzkpYeMeta?.name ?? "privacy-policy___en",
    path: privacy_45policy6lIvnzkpYeMeta?.path ?? "/privacy-policy",
    meta: privacy_45policy6lIvnzkpYeMeta || {},
    alias: privacy_45policy6lIvnzkpYeMeta?.alias || [],
    redirect: privacy_45policy6lIvnzkpYeMeta?.redirect,
    component: () => import("/src/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policy6lIvnzkpYeMeta?.name ?? "privacy-policy___ar",
    path: privacy_45policy6lIvnzkpYeMeta?.path ?? "/ar/privacy-policy",
    meta: privacy_45policy6lIvnzkpYeMeta || {},
    alias: privacy_45policy6lIvnzkpYeMeta?.alias || [],
    redirect: privacy_45policy6lIvnzkpYeMeta?.redirect,
    component: () => import("/src/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policy6lIvnzkpYeMeta?.name ?? "privacy-policy___mn",
    path: privacy_45policy6lIvnzkpYeMeta?.path ?? "/mn/privacy-policy",
    meta: privacy_45policy6lIvnzkpYeMeta || {},
    alias: privacy_45policy6lIvnzkpYeMeta?.alias || [],
    redirect: privacy_45policy6lIvnzkpYeMeta?.redirect,
    component: () => import("/src/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policy6lIvnzkpYeMeta?.name ?? "privacy-policy___ru",
    path: privacy_45policy6lIvnzkpYeMeta?.path ?? "/ru/privacy-policy",
    meta: privacy_45policy6lIvnzkpYeMeta || {},
    alias: privacy_45policy6lIvnzkpYeMeta?.alias || [],
    redirect: privacy_45policy6lIvnzkpYeMeta?.redirect,
    component: () => import("/src/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policy6lIvnzkpYeMeta?.name ?? "privacy-policy___kk",
    path: privacy_45policy6lIvnzkpYeMeta?.path ?? "/kk/privacy-policy",
    meta: privacy_45policy6lIvnzkpYeMeta || {},
    alias: privacy_45policy6lIvnzkpYeMeta?.alias || [],
    redirect: privacy_45policy6lIvnzkpYeMeta?.redirect,
    component: () => import("/src/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policy6lIvnzkpYeMeta?.name ?? "privacy-policy___zh",
    path: privacy_45policy6lIvnzkpYeMeta?.path ?? "/zh/privacy-policy",
    meta: privacy_45policy6lIvnzkpYeMeta || {},
    alias: privacy_45policy6lIvnzkpYeMeta?.alias || [],
    redirect: privacy_45policy6lIvnzkpYeMeta?.redirect,
    component: () => import("/src/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policy6lIvnzkpYeMeta?.name ?? "privacy-policy___tr",
    path: privacy_45policy6lIvnzkpYeMeta?.path ?? "/tr/privacy-policy",
    meta: privacy_45policy6lIvnzkpYeMeta || {},
    alias: privacy_45policy6lIvnzkpYeMeta?.alias || [],
    redirect: privacy_45policy6lIvnzkpYeMeta?.redirect,
    component: () => import("/src/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policy6lIvnzkpYeMeta?.name ?? "privacy-policy___hy",
    path: privacy_45policy6lIvnzkpYeMeta?.path ?? "/hy/privacy-policy",
    meta: privacy_45policy6lIvnzkpYeMeta || {},
    alias: privacy_45policy6lIvnzkpYeMeta?.alias || [],
    redirect: privacy_45policy6lIvnzkpYeMeta?.redirect,
    component: () => import("/src/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: pro_45bonoAM3ufo0FYHMeta?.name ?? "pro-bono___en",
    path: pro_45bonoAM3ufo0FYHMeta?.path ?? "/pro-bono",
    meta: pro_45bonoAM3ufo0FYHMeta || {},
    alias: pro_45bonoAM3ufo0FYHMeta?.alias || [],
    redirect: pro_45bonoAM3ufo0FYHMeta?.redirect,
    component: () => import("/src/pages/pro-bono.vue").then(m => m.default || m)
  },
  {
    name: pro_45bonoAM3ufo0FYHMeta?.name ?? "pro-bono___ar",
    path: pro_45bonoAM3ufo0FYHMeta?.path ?? "/ar/pro-bono",
    meta: pro_45bonoAM3ufo0FYHMeta || {},
    alias: pro_45bonoAM3ufo0FYHMeta?.alias || [],
    redirect: pro_45bonoAM3ufo0FYHMeta?.redirect,
    component: () => import("/src/pages/pro-bono.vue").then(m => m.default || m)
  },
  {
    name: pro_45bonoAM3ufo0FYHMeta?.name ?? "pro-bono___mn",
    path: pro_45bonoAM3ufo0FYHMeta?.path ?? "/mn/pro-bono",
    meta: pro_45bonoAM3ufo0FYHMeta || {},
    alias: pro_45bonoAM3ufo0FYHMeta?.alias || [],
    redirect: pro_45bonoAM3ufo0FYHMeta?.redirect,
    component: () => import("/src/pages/pro-bono.vue").then(m => m.default || m)
  },
  {
    name: pro_45bonoAM3ufo0FYHMeta?.name ?? "pro-bono___ru",
    path: pro_45bonoAM3ufo0FYHMeta?.path ?? "/ru/pro-bono",
    meta: pro_45bonoAM3ufo0FYHMeta || {},
    alias: pro_45bonoAM3ufo0FYHMeta?.alias || [],
    redirect: pro_45bonoAM3ufo0FYHMeta?.redirect,
    component: () => import("/src/pages/pro-bono.vue").then(m => m.default || m)
  },
  {
    name: pro_45bonoAM3ufo0FYHMeta?.name ?? "pro-bono___kk",
    path: pro_45bonoAM3ufo0FYHMeta?.path ?? "/kk/pro-bono",
    meta: pro_45bonoAM3ufo0FYHMeta || {},
    alias: pro_45bonoAM3ufo0FYHMeta?.alias || [],
    redirect: pro_45bonoAM3ufo0FYHMeta?.redirect,
    component: () => import("/src/pages/pro-bono.vue").then(m => m.default || m)
  },
  {
    name: pro_45bonoAM3ufo0FYHMeta?.name ?? "pro-bono___zh",
    path: pro_45bonoAM3ufo0FYHMeta?.path ?? "/zh/pro-bono",
    meta: pro_45bonoAM3ufo0FYHMeta || {},
    alias: pro_45bonoAM3ufo0FYHMeta?.alias || [],
    redirect: pro_45bonoAM3ufo0FYHMeta?.redirect,
    component: () => import("/src/pages/pro-bono.vue").then(m => m.default || m)
  },
  {
    name: pro_45bonoAM3ufo0FYHMeta?.name ?? "pro-bono___tr",
    path: pro_45bonoAM3ufo0FYHMeta?.path ?? "/tr/pro-bono",
    meta: pro_45bonoAM3ufo0FYHMeta || {},
    alias: pro_45bonoAM3ufo0FYHMeta?.alias || [],
    redirect: pro_45bonoAM3ufo0FYHMeta?.redirect,
    component: () => import("/src/pages/pro-bono.vue").then(m => m.default || m)
  },
  {
    name: pro_45bonoAM3ufo0FYHMeta?.name ?? "pro-bono___hy",
    path: pro_45bonoAM3ufo0FYHMeta?.path ?? "/hy/pro-bono",
    meta: pro_45bonoAM3ufo0FYHMeta || {},
    alias: pro_45bonoAM3ufo0FYHMeta?.alias || [],
    redirect: pro_45bonoAM3ufo0FYHMeta?.redirect,
    component: () => import("/src/pages/pro-bono.vue").then(m => m.default || m)
  },
  {
    name: _91publicationSlug_93zcQOhtCLK9Meta?.name ?? "publications-publicationSlug___en",
    path: _91publicationSlug_93zcQOhtCLK9Meta?.path ?? "/publications/:publicationSlug()",
    meta: _91publicationSlug_93zcQOhtCLK9Meta || {},
    alias: _91publicationSlug_93zcQOhtCLK9Meta?.alias || [],
    redirect: _91publicationSlug_93zcQOhtCLK9Meta?.redirect,
    component: () => import("/src/pages/publications/[publicationSlug].vue").then(m => m.default || m)
  },
  {
    name: _91publicationSlug_93zcQOhtCLK9Meta?.name ?? "publications-publicationSlug___ar",
    path: _91publicationSlug_93zcQOhtCLK9Meta?.path ?? "/ar/publications/:publicationSlug()",
    meta: _91publicationSlug_93zcQOhtCLK9Meta || {},
    alias: _91publicationSlug_93zcQOhtCLK9Meta?.alias || [],
    redirect: _91publicationSlug_93zcQOhtCLK9Meta?.redirect,
    component: () => import("/src/pages/publications/[publicationSlug].vue").then(m => m.default || m)
  },
  {
    name: _91publicationSlug_93zcQOhtCLK9Meta?.name ?? "publications-publicationSlug___mn",
    path: _91publicationSlug_93zcQOhtCLK9Meta?.path ?? "/mn/publications/:publicationSlug()",
    meta: _91publicationSlug_93zcQOhtCLK9Meta || {},
    alias: _91publicationSlug_93zcQOhtCLK9Meta?.alias || [],
    redirect: _91publicationSlug_93zcQOhtCLK9Meta?.redirect,
    component: () => import("/src/pages/publications/[publicationSlug].vue").then(m => m.default || m)
  },
  {
    name: _91publicationSlug_93zcQOhtCLK9Meta?.name ?? "publications-publicationSlug___ru",
    path: _91publicationSlug_93zcQOhtCLK9Meta?.path ?? "/ru/publications/:publicationSlug()",
    meta: _91publicationSlug_93zcQOhtCLK9Meta || {},
    alias: _91publicationSlug_93zcQOhtCLK9Meta?.alias || [],
    redirect: _91publicationSlug_93zcQOhtCLK9Meta?.redirect,
    component: () => import("/src/pages/publications/[publicationSlug].vue").then(m => m.default || m)
  },
  {
    name: _91publicationSlug_93zcQOhtCLK9Meta?.name ?? "publications-publicationSlug___kk",
    path: _91publicationSlug_93zcQOhtCLK9Meta?.path ?? "/kk/publications/:publicationSlug()",
    meta: _91publicationSlug_93zcQOhtCLK9Meta || {},
    alias: _91publicationSlug_93zcQOhtCLK9Meta?.alias || [],
    redirect: _91publicationSlug_93zcQOhtCLK9Meta?.redirect,
    component: () => import("/src/pages/publications/[publicationSlug].vue").then(m => m.default || m)
  },
  {
    name: _91publicationSlug_93zcQOhtCLK9Meta?.name ?? "publications-publicationSlug___zh",
    path: _91publicationSlug_93zcQOhtCLK9Meta?.path ?? "/zh/publications/:publicationSlug()",
    meta: _91publicationSlug_93zcQOhtCLK9Meta || {},
    alias: _91publicationSlug_93zcQOhtCLK9Meta?.alias || [],
    redirect: _91publicationSlug_93zcQOhtCLK9Meta?.redirect,
    component: () => import("/src/pages/publications/[publicationSlug].vue").then(m => m.default || m)
  },
  {
    name: _91publicationSlug_93zcQOhtCLK9Meta?.name ?? "publications-publicationSlug___tr",
    path: _91publicationSlug_93zcQOhtCLK9Meta?.path ?? "/tr/publications/:publicationSlug()",
    meta: _91publicationSlug_93zcQOhtCLK9Meta || {},
    alias: _91publicationSlug_93zcQOhtCLK9Meta?.alias || [],
    redirect: _91publicationSlug_93zcQOhtCLK9Meta?.redirect,
    component: () => import("/src/pages/publications/[publicationSlug].vue").then(m => m.default || m)
  },
  {
    name: _91publicationSlug_93zcQOhtCLK9Meta?.name ?? "publications-publicationSlug___hy",
    path: _91publicationSlug_93zcQOhtCLK9Meta?.path ?? "/hy/publications/:publicationSlug()",
    meta: _91publicationSlug_93zcQOhtCLK9Meta || {},
    alias: _91publicationSlug_93zcQOhtCLK9Meta?.alias || [],
    redirect: _91publicationSlug_93zcQOhtCLK9Meta?.redirect,
    component: () => import("/src/pages/publications/[publicationSlug].vue").then(m => m.default || m)
  },
  {
    name: recognitionjC8ahfO9yCMeta?.name ?? "recognition___en",
    path: recognitionjC8ahfO9yCMeta?.path ?? "/recognition",
    meta: recognitionjC8ahfO9yCMeta || {},
    alias: recognitionjC8ahfO9yCMeta?.alias || [],
    redirect: recognitionjC8ahfO9yCMeta?.redirect,
    component: () => import("/src/pages/recognition.vue").then(m => m.default || m)
  },
  {
    name: recognitionjC8ahfO9yCMeta?.name ?? "recognition___ar",
    path: recognitionjC8ahfO9yCMeta?.path ?? "/ar/recognition",
    meta: recognitionjC8ahfO9yCMeta || {},
    alias: recognitionjC8ahfO9yCMeta?.alias || [],
    redirect: recognitionjC8ahfO9yCMeta?.redirect,
    component: () => import("/src/pages/recognition.vue").then(m => m.default || m)
  },
  {
    name: recognitionjC8ahfO9yCMeta?.name ?? "recognition___mn",
    path: recognitionjC8ahfO9yCMeta?.path ?? "/mn/recognition",
    meta: recognitionjC8ahfO9yCMeta || {},
    alias: recognitionjC8ahfO9yCMeta?.alias || [],
    redirect: recognitionjC8ahfO9yCMeta?.redirect,
    component: () => import("/src/pages/recognition.vue").then(m => m.default || m)
  },
  {
    name: recognitionjC8ahfO9yCMeta?.name ?? "recognition___ru",
    path: recognitionjC8ahfO9yCMeta?.path ?? "/ru/recognition",
    meta: recognitionjC8ahfO9yCMeta || {},
    alias: recognitionjC8ahfO9yCMeta?.alias || [],
    redirect: recognitionjC8ahfO9yCMeta?.redirect,
    component: () => import("/src/pages/recognition.vue").then(m => m.default || m)
  },
  {
    name: recognitionjC8ahfO9yCMeta?.name ?? "recognition___kk",
    path: recognitionjC8ahfO9yCMeta?.path ?? "/kk/recognition",
    meta: recognitionjC8ahfO9yCMeta || {},
    alias: recognitionjC8ahfO9yCMeta?.alias || [],
    redirect: recognitionjC8ahfO9yCMeta?.redirect,
    component: () => import("/src/pages/recognition.vue").then(m => m.default || m)
  },
  {
    name: recognitionjC8ahfO9yCMeta?.name ?? "recognition___zh",
    path: recognitionjC8ahfO9yCMeta?.path ?? "/zh/recognition",
    meta: recognitionjC8ahfO9yCMeta || {},
    alias: recognitionjC8ahfO9yCMeta?.alias || [],
    redirect: recognitionjC8ahfO9yCMeta?.redirect,
    component: () => import("/src/pages/recognition.vue").then(m => m.default || m)
  },
  {
    name: recognitionjC8ahfO9yCMeta?.name ?? "recognition___tr",
    path: recognitionjC8ahfO9yCMeta?.path ?? "/tr/recognition",
    meta: recognitionjC8ahfO9yCMeta || {},
    alias: recognitionjC8ahfO9yCMeta?.alias || [],
    redirect: recognitionjC8ahfO9yCMeta?.redirect,
    component: () => import("/src/pages/recognition.vue").then(m => m.default || m)
  },
  {
    name: recognitionjC8ahfO9yCMeta?.name ?? "recognition___hy",
    path: recognitionjC8ahfO9yCMeta?.path ?? "/hy/recognition",
    meta: recognitionjC8ahfO9yCMeta || {},
    alias: recognitionjC8ahfO9yCMeta?.alias || [],
    redirect: recognitionjC8ahfO9yCMeta?.redirect,
    component: () => import("/src/pages/recognition.vue").then(m => m.default || m)
  },
  {
    name: indexHHxIP9fb8kMeta?.name ?? "regions___en",
    path: indexHHxIP9fb8kMeta?.path ?? "/regions",
    meta: indexHHxIP9fb8kMeta || {},
    alias: indexHHxIP9fb8kMeta?.alias || [],
    redirect: indexHHxIP9fb8kMeta?.redirect,
    component: () => import("/src/pages/regions/index.vue").then(m => m.default || m)
  },
  {
    name: indexHHxIP9fb8kMeta?.name ?? "regions___ar",
    path: indexHHxIP9fb8kMeta?.path ?? "/ar/regions",
    meta: indexHHxIP9fb8kMeta || {},
    alias: indexHHxIP9fb8kMeta?.alias || [],
    redirect: indexHHxIP9fb8kMeta?.redirect,
    component: () => import("/src/pages/regions/index.vue").then(m => m.default || m)
  },
  {
    name: indexHHxIP9fb8kMeta?.name ?? "regions___mn",
    path: indexHHxIP9fb8kMeta?.path ?? "/mn/regions",
    meta: indexHHxIP9fb8kMeta || {},
    alias: indexHHxIP9fb8kMeta?.alias || [],
    redirect: indexHHxIP9fb8kMeta?.redirect,
    component: () => import("/src/pages/regions/index.vue").then(m => m.default || m)
  },
  {
    name: indexHHxIP9fb8kMeta?.name ?? "regions___ru",
    path: indexHHxIP9fb8kMeta?.path ?? "/ru/regions",
    meta: indexHHxIP9fb8kMeta || {},
    alias: indexHHxIP9fb8kMeta?.alias || [],
    redirect: indexHHxIP9fb8kMeta?.redirect,
    component: () => import("/src/pages/regions/index.vue").then(m => m.default || m)
  },
  {
    name: indexHHxIP9fb8kMeta?.name ?? "regions___kk",
    path: indexHHxIP9fb8kMeta?.path ?? "/kk/regions",
    meta: indexHHxIP9fb8kMeta || {},
    alias: indexHHxIP9fb8kMeta?.alias || [],
    redirect: indexHHxIP9fb8kMeta?.redirect,
    component: () => import("/src/pages/regions/index.vue").then(m => m.default || m)
  },
  {
    name: indexHHxIP9fb8kMeta?.name ?? "regions___zh",
    path: indexHHxIP9fb8kMeta?.path ?? "/zh/regions",
    meta: indexHHxIP9fb8kMeta || {},
    alias: indexHHxIP9fb8kMeta?.alias || [],
    redirect: indexHHxIP9fb8kMeta?.redirect,
    component: () => import("/src/pages/regions/index.vue").then(m => m.default || m)
  },
  {
    name: indexHHxIP9fb8kMeta?.name ?? "regions___tr",
    path: indexHHxIP9fb8kMeta?.path ?? "/tr/regions",
    meta: indexHHxIP9fb8kMeta || {},
    alias: indexHHxIP9fb8kMeta?.alias || [],
    redirect: indexHHxIP9fb8kMeta?.redirect,
    component: () => import("/src/pages/regions/index.vue").then(m => m.default || m)
  },
  {
    name: indexHHxIP9fb8kMeta?.name ?? "regions___hy",
    path: indexHHxIP9fb8kMeta?.path ?? "/hy/regions",
    meta: indexHHxIP9fb8kMeta || {},
    alias: indexHHxIP9fb8kMeta?.alias || [],
    redirect: indexHHxIP9fb8kMeta?.redirect,
    component: () => import("/src/pages/regions/index.vue").then(m => m.default || m)
  },
  {
    name: sitemapiIks4zSRrbMeta?.name ?? "sitemap___en",
    path: sitemapiIks4zSRrbMeta?.path ?? "/sitemap",
    meta: sitemapiIks4zSRrbMeta || {},
    alias: sitemapiIks4zSRrbMeta?.alias || [],
    redirect: sitemapiIks4zSRrbMeta?.redirect,
    component: () => import("/src/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: sitemapiIks4zSRrbMeta?.name ?? "sitemap___ar",
    path: sitemapiIks4zSRrbMeta?.path ?? "/ar/sitemap",
    meta: sitemapiIks4zSRrbMeta || {},
    alias: sitemapiIks4zSRrbMeta?.alias || [],
    redirect: sitemapiIks4zSRrbMeta?.redirect,
    component: () => import("/src/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: sitemapiIks4zSRrbMeta?.name ?? "sitemap___mn",
    path: sitemapiIks4zSRrbMeta?.path ?? "/mn/sitemap",
    meta: sitemapiIks4zSRrbMeta || {},
    alias: sitemapiIks4zSRrbMeta?.alias || [],
    redirect: sitemapiIks4zSRrbMeta?.redirect,
    component: () => import("/src/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: sitemapiIks4zSRrbMeta?.name ?? "sitemap___ru",
    path: sitemapiIks4zSRrbMeta?.path ?? "/ru/sitemap",
    meta: sitemapiIks4zSRrbMeta || {},
    alias: sitemapiIks4zSRrbMeta?.alias || [],
    redirect: sitemapiIks4zSRrbMeta?.redirect,
    component: () => import("/src/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: sitemapiIks4zSRrbMeta?.name ?? "sitemap___kk",
    path: sitemapiIks4zSRrbMeta?.path ?? "/kk/sitemap",
    meta: sitemapiIks4zSRrbMeta || {},
    alias: sitemapiIks4zSRrbMeta?.alias || [],
    redirect: sitemapiIks4zSRrbMeta?.redirect,
    component: () => import("/src/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: sitemapiIks4zSRrbMeta?.name ?? "sitemap___zh",
    path: sitemapiIks4zSRrbMeta?.path ?? "/zh/sitemap",
    meta: sitemapiIks4zSRrbMeta || {},
    alias: sitemapiIks4zSRrbMeta?.alias || [],
    redirect: sitemapiIks4zSRrbMeta?.redirect,
    component: () => import("/src/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: sitemapiIks4zSRrbMeta?.name ?? "sitemap___tr",
    path: sitemapiIks4zSRrbMeta?.path ?? "/tr/sitemap",
    meta: sitemapiIks4zSRrbMeta || {},
    alias: sitemapiIks4zSRrbMeta?.alias || [],
    redirect: sitemapiIks4zSRrbMeta?.redirect,
    component: () => import("/src/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: sitemapiIks4zSRrbMeta?.name ?? "sitemap___hy",
    path: sitemapiIks4zSRrbMeta?.path ?? "/hy/sitemap",
    meta: sitemapiIks4zSRrbMeta || {},
    alias: sitemapiIks4zSRrbMeta?.alias || [],
    redirect: sitemapiIks4zSRrbMeta?.redirect,
    component: () => import("/src/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: _91slug_930dCnukiiaiMeta?.name ?? "specializations-slug___en",
    path: _91slug_930dCnukiiaiMeta?.path ?? "/specializations/:slug()",
    meta: _91slug_930dCnukiiaiMeta || {},
    alias: _91slug_930dCnukiiaiMeta?.alias || [],
    redirect: _91slug_930dCnukiiaiMeta?.redirect,
    component: () => import("/src/pages/specializations/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930dCnukiiaiMeta?.name ?? "specializations-slug___ar",
    path: _91slug_930dCnukiiaiMeta?.path ?? "/ar/specializations/:slug()",
    meta: _91slug_930dCnukiiaiMeta || {},
    alias: _91slug_930dCnukiiaiMeta?.alias || [],
    redirect: _91slug_930dCnukiiaiMeta?.redirect,
    component: () => import("/src/pages/specializations/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930dCnukiiaiMeta?.name ?? "specializations-slug___mn",
    path: _91slug_930dCnukiiaiMeta?.path ?? "/mn/specializations/:slug()",
    meta: _91slug_930dCnukiiaiMeta || {},
    alias: _91slug_930dCnukiiaiMeta?.alias || [],
    redirect: _91slug_930dCnukiiaiMeta?.redirect,
    component: () => import("/src/pages/specializations/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930dCnukiiaiMeta?.name ?? "specializations-slug___ru",
    path: _91slug_930dCnukiiaiMeta?.path ?? "/ru/specializations/:slug()",
    meta: _91slug_930dCnukiiaiMeta || {},
    alias: _91slug_930dCnukiiaiMeta?.alias || [],
    redirect: _91slug_930dCnukiiaiMeta?.redirect,
    component: () => import("/src/pages/specializations/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930dCnukiiaiMeta?.name ?? "specializations-slug___kk",
    path: _91slug_930dCnukiiaiMeta?.path ?? "/kk/specializations/:slug()",
    meta: _91slug_930dCnukiiaiMeta || {},
    alias: _91slug_930dCnukiiaiMeta?.alias || [],
    redirect: _91slug_930dCnukiiaiMeta?.redirect,
    component: () => import("/src/pages/specializations/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930dCnukiiaiMeta?.name ?? "specializations-slug___zh",
    path: _91slug_930dCnukiiaiMeta?.path ?? "/zh/specializations/:slug()",
    meta: _91slug_930dCnukiiaiMeta || {},
    alias: _91slug_930dCnukiiaiMeta?.alias || [],
    redirect: _91slug_930dCnukiiaiMeta?.redirect,
    component: () => import("/src/pages/specializations/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930dCnukiiaiMeta?.name ?? "specializations-slug___tr",
    path: _91slug_930dCnukiiaiMeta?.path ?? "/tr/specializations/:slug()",
    meta: _91slug_930dCnukiiaiMeta || {},
    alias: _91slug_930dCnukiiaiMeta?.alias || [],
    redirect: _91slug_930dCnukiiaiMeta?.redirect,
    component: () => import("/src/pages/specializations/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930dCnukiiaiMeta?.name ?? "specializations-slug___hy",
    path: _91slug_930dCnukiiaiMeta?.path ?? "/hy/specializations/:slug()",
    meta: _91slug_930dCnukiiaiMeta || {},
    alias: _91slug_930dCnukiiaiMeta?.alias || [],
    redirect: _91slug_930dCnukiiaiMeta?.redirect,
    component: () => import("/src/pages/specializations/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexyyzAurYdqzMeta?.name ?? "specializations___en",
    path: indexyyzAurYdqzMeta?.path ?? "/specializations",
    meta: indexyyzAurYdqzMeta || {},
    alias: indexyyzAurYdqzMeta?.alias || [],
    redirect: indexyyzAurYdqzMeta?.redirect,
    component: () => import("/src/pages/specializations/index.vue").then(m => m.default || m)
  },
  {
    name: indexyyzAurYdqzMeta?.name ?? "specializations___ar",
    path: indexyyzAurYdqzMeta?.path ?? "/ar/specializations",
    meta: indexyyzAurYdqzMeta || {},
    alias: indexyyzAurYdqzMeta?.alias || [],
    redirect: indexyyzAurYdqzMeta?.redirect,
    component: () => import("/src/pages/specializations/index.vue").then(m => m.default || m)
  },
  {
    name: indexyyzAurYdqzMeta?.name ?? "specializations___mn",
    path: indexyyzAurYdqzMeta?.path ?? "/mn/specializations",
    meta: indexyyzAurYdqzMeta || {},
    alias: indexyyzAurYdqzMeta?.alias || [],
    redirect: indexyyzAurYdqzMeta?.redirect,
    component: () => import("/src/pages/specializations/index.vue").then(m => m.default || m)
  },
  {
    name: indexyyzAurYdqzMeta?.name ?? "specializations___ru",
    path: indexyyzAurYdqzMeta?.path ?? "/ru/specializations",
    meta: indexyyzAurYdqzMeta || {},
    alias: indexyyzAurYdqzMeta?.alias || [],
    redirect: indexyyzAurYdqzMeta?.redirect,
    component: () => import("/src/pages/specializations/index.vue").then(m => m.default || m)
  },
  {
    name: indexyyzAurYdqzMeta?.name ?? "specializations___kk",
    path: indexyyzAurYdqzMeta?.path ?? "/kk/specializations",
    meta: indexyyzAurYdqzMeta || {},
    alias: indexyyzAurYdqzMeta?.alias || [],
    redirect: indexyyzAurYdqzMeta?.redirect,
    component: () => import("/src/pages/specializations/index.vue").then(m => m.default || m)
  },
  {
    name: indexyyzAurYdqzMeta?.name ?? "specializations___zh",
    path: indexyyzAurYdqzMeta?.path ?? "/zh/specializations",
    meta: indexyyzAurYdqzMeta || {},
    alias: indexyyzAurYdqzMeta?.alias || [],
    redirect: indexyyzAurYdqzMeta?.redirect,
    component: () => import("/src/pages/specializations/index.vue").then(m => m.default || m)
  },
  {
    name: indexyyzAurYdqzMeta?.name ?? "specializations___tr",
    path: indexyyzAurYdqzMeta?.path ?? "/tr/specializations",
    meta: indexyyzAurYdqzMeta || {},
    alias: indexyyzAurYdqzMeta?.alias || [],
    redirect: indexyyzAurYdqzMeta?.redirect,
    component: () => import("/src/pages/specializations/index.vue").then(m => m.default || m)
  },
  {
    name: indexyyzAurYdqzMeta?.name ?? "specializations___hy",
    path: indexyyzAurYdqzMeta?.path ?? "/hy/specializations",
    meta: indexyyzAurYdqzMeta || {},
    alias: indexyyzAurYdqzMeta?.alias || [],
    redirect: indexyyzAurYdqzMeta?.redirect,
    component: () => import("/src/pages/specializations/index.vue").then(m => m.default || m)
  },
  {
    name: component_45stubsLJBURSTwrMeta?.name ?? undefined,
    path: component_45stubsLJBURSTwrMeta?.path ?? "/sitemap.xml",
    meta: component_45stubsLJBURSTwrMeta || {},
    alias: component_45stubsLJBURSTwrMeta?.alias || [],
    redirect: component_45stubsLJBURSTwrMeta?.redirect,
    component: component_45stubsLJBURSTwr
  }
]